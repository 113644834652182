import React from "react";
import { Box, Typography } from "@mui/material";

interface LastCatchBoxProps {
  lastValidation: number | null;
  catchCount: number;
}

const LastCatchBox = ({ lastValidation, catchCount }: LastCatchBoxProps) => {
  return (
    <Box
      sx={{
        border: "1px solid #27382C",
        borderRadius: "8px",
        minWidth: "120px",
        overflow: "hidden",
        marginTop: "12px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "white",
          backgroundColor: "#27382C",
          display: "block",
          padding: "4px 8px",
          fontWeight: 600,
        }}
      >
        LAST CATCH
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
        <Typography variant="body2">
          {lastValidation
            ? Date.now() / 1000 - lastValidation < 86400
              ? "Today"
              : `${Math.floor((Date.now() / 1000 - lastValidation) / 86400)} days ago`
            : "No catch data"}
        </Typography>
        {catchCount > 0 && (
          <Typography variant="body2" sx={{ fontWeight: 600, ml: 1 }}>
            {catchCount}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LastCatchBox;

import React, { useState } from "react";
import {
    Typography,
    Button,
    TextField,
    Container,
    Box
} from "@mui/material";
import { createCorporation } from "queries/corporations";
import { useNavigate } from "react-router-dom";
import { setTokens } from "utils/token";
import Cookies from "js-cookie";
import { refreshToken } from "queries/auth";
import ROUTES from "routes";


const CreateOrganization: React.FC = () => {

    const [organizationName, setOrganizationName] = useState<string>("")
    const navigate = useNavigate();
    const goToUserManagement = () => {
        navigate(ROUTES.CORPORATION_USER_MANAGEMENT, { replace: true });
    }
    const [isAlertShown, setIsAlertShown] = useState<boolean>(false);

    const clearAllWindowsCache = () => {
        if ("caches" in window) {
            const clearIt = async () => {
                const cacheKeys = await window.caches.keys();
                await Promise.all(
                    cacheKeys.map((key) => {
                        return window.caches.delete(key);
                    })
                );
            };
            clearIt();
        }
        localStorage.clear();
    }

    const onCreateOrganizationClick = async () => {

        try {
            await createCorporation({ name: organizationName });
            const token = Cookies.get("refresh")
            if (token) {
                const newTokens = await refreshToken({ refresh: token })
                clearAllWindowsCache();
                setTokens(newTokens)
                goToUserManagement();
            }
        } catch (error) {
            setIsAlertShown(true);
        }
    }

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                minHeight: "5em",
                bgcolor: "#ffff",
                padding: 4,
                borderRadius: 4,
                minWidth: "89vw"
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    textAlign: "left",
                    marginBottom: 0.5,
                    color: "#555",
                    fontWeight: "bold"
                }}
            >
                Name your organization
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    textAlign: "left",
                    marginBottom: 2,
                    color: "#555",
                }}
            >
                Invited users will see this organization name in the invitation.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, gap: 2, justifyContent: "space-between", width: "100%" }}>
                <TextField

                    size="small"
                    label="Organization name"
                    variant="outlined"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                />
                <Button
                    variant="contained"
                    sx={{
                        alignSelf: "end",
                        float: "right"
                    }}
                    onClick={onCreateOrganizationClick}
                >
                    Create
                </Button>
            </Box>
        </Container>
    );
};

export default CreateOrganization;

/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { ListResponse } from "queries/types";
import { listAllFactory } from "utils/query";

type OrderingParams = "created_at" | "-created_at";

export type ImageCoordinateSource = "human" | "ai";

export type ImageCoordinates = {
  x: number;
  y: number;
  w: number;
  h: number;
  source: ImageCoordinateSource;
}[];

export type ListImagesParams = {
  page?: number;
  page_size?: number;
  created_at__lte?: number;
  created_at__gte?: number;
  id__in?: string[];
  device__id?: string;
  device__id__in?: string[];
  ordering?: OrderingParams | OrderingParams[];
  is_validated?: boolean;
  is_visible?: boolean;
};

export type ListImageItem = {
  id: string;
  created_at: number;
  updated_at: number;
  device: string;
  detection_count_delta: number;
  comment: string | null;
  raw_blob_name: string;
  processed_blob_name: string | null;
  validated_blob_name: string | null;
  delta_blob_name: string | null;
  detection_count: number | null;
  is_validated: boolean;
  coordinates: ImageCoordinates;
  original_ai_coordinates: ImageCoordinates;
  is_visible: boolean;
  is_dark: boolean;
  is_shiny: boolean;
  is_blurry: boolean;
  fullness_level: number | null;
  requires_cleaning: boolean;
  gcimgs_url?: string;
};

export const listImages = async (
  params?: ListImagesParams
): Promise<ListResponse<ListImageItem>> => {
  const { data } = await axios.get<ListResponse<ListImageItem>>("/api/devices/images/", { params });
  return data;
};
export const listAllImages = listAllFactory(listImages);

type DetailImageItem = ListImageItem;

export type DetailImageParams = {
  id: string;
};

export const detailImage = async ({ id }: DetailImageParams): Promise<DetailImageItem> => {
  const { data } = await axios.get<DetailImageItem>(`/api/devices/images/${id}/`);
  return data;
};

type CleanedImageParams = {
  id: string;
};

export type UpdateImageParams = {
  id: string;
  body: {
    created_at?: number;
    updated_at?: number;
    device?: string;
    comment?: string | null;
    detection_count?: number | null;
    is_validated?: boolean;
    is_visible?: boolean;
    is_dark?: boolean;
    is_shiny?: boolean;
    is_blurry?: boolean;
    fullness_level?: number | null;
    requires_cleaning?: boolean;
  };
};

export type UpdateImageCoordinateParams = {
  id: string;
  body: {
    coordinates: ImageCoordinates;
  };
};

export const cleanedImage = async ({ id }: CleanedImageParams): Promise<void> => {
  const { data } = await axios.post(`/api/devices/images/${id}/cleaned`);
  return data;
};

export const updateImage = async ({ id, body }: UpdateImageParams): Promise<DetailImageItem> => {
  const { data } = await axios.patch<DetailImageItem>(`/api/devices/images/${id}/`, body);
  return data;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum IMAGE_TYPES {
  raw = "raw",
  processed = "processed",
  validated = "validated",
  delta = "delta",
}

type DownloadImageParams = {
  id: string;
  type: IMAGE_TYPES;
};

type DownloadSignedURLImageParams = {
  url: string;
};
export const downloadImage = async ({ id, type }: DownloadImageParams): Promise<Blob> => {
  const { data } = await axios.get<Blob>(`/api/devices/images/${id}/download/`, {
    responseType: "blob",
    params: { type },
  });
  return data;
};

export const downloadSignedUrlImage = async ({
  url,
}: DownloadSignedURLImageParams): Promise<Blob> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error: ${response.status}`);
  }
  const data = await response.blob();
  return data;
};

type UploadImageParams = {
  id: string;
  type: IMAGE_TYPES;
  blob: Blob;
};

export const uploadImage = async ({ id, type, blob }: UploadImageParams): Promise<void> => {
  const { data } = await axios.post<void>(`/api/devices/images/${id}/${type}/`, blob, {
    headers: {
      "Content-Type": "image/jpeg",
    },
  });
  return data;
};

export const uploadImageCoordinates = async ({
  id,
  body,
}: UpdateImageCoordinateParams): Promise<void> => {
  const { data } = await axios.post<void>(`/api/devices/images/${id}/coordinate/`, body);
  return data;
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getInvitaionList, ListInviteItem, resendInvitation, sendInvitation } from "queries/inviteUser";
import { useMemo } from "react";


const useInviteManage = () => {

  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: ["invitations"],
    queryFn: getInvitaionList,
    refetchInterval: (data) => {
      if (data?.data && data.data.some((invite: ListInviteItem) => invite.status === "pending")) {
        return 10000;
      }
      return false;
    },
    refetchIntervalInBackground: true,
  });

  const invitations = useMemo(() => data?.data || [], [data?.data]);

  const sendInviteMutation = useMutation({
    mutationFn: sendInvitation,
    onSuccess: (newInvite) => {
      queryClient.invalidateQueries(["invitations"]);
    },
  });

  const resendInviteMutation = useMutation({
    mutationFn: resendInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries(["invitations"]);
    },
  });

  const sendInvitationWrap = async (params: Parameters<typeof sendInvitation>[0]): Promise<void> => {
    await sendInviteMutation.mutateAsync(params);
  };

  return {
    invitations,
    isLoading,
    error,
    sendInvitation: sendInvitationWrap,
    resendInvitation: resendInviteMutation.mutate,
    isSending: sendInviteMutation.isLoading,
    isReSending: sendInviteMutation.isLoading
  }

}

export default useInviteManage
import { Chip, Stack, Box } from "@mui/material";
import { DEVICE_GENERAL_STATUSES, generalStatusToExplanation } from "utils/device-statuses";
import { Cancel, Done, SelectAll } from "@mui/icons-material";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getDeviceStatusSummary } from "queries/devices";
import useDeviceFilters from "hooks/useDeviceFilters";

const CountDisplay = ({ number, isActive }: { number: number; isActive: boolean }) => {
  if (number === 0) {
    return null;
  }
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: isActive ? "white" : theme.palette.grey[300],
        color: theme.palette.primary.contrastText, // to ensure text contrast
        px: 1,
        borderRadius: 100000,
      })}
    >
      {number}
    </Box>
  );
};
const DeviceGeneralStatusFilterChips = () => {
  const { toggleStatus, setAllStatuses, activeStatuses } = useDeviceFilters();

  const { data: countsByStatus } = useQuery({
    queryKey: ["device-counts-by-status"],
    queryFn: async () => {
      const data = await getDeviceStatusSummary();

      const counts = DEVICE_GENERAL_STATUSES.map((status) => {
        return {
          [status]: data.general.find((item) => item.status_general === status)?.number ?? 0,
        };
      });

      return counts.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    },
    staleTime: Infinity,
  });

  const allSelected = activeStatuses.length === DEVICE_GENERAL_STATUSES.length;

  return (
    <Stack
      useFlexGap
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      spacing={1}
      sx={{ pt: 2, pb: 4 }}
    >
      <Chip
        label={allSelected ? "Deselect all" : "Select all"}
        variant={allSelected ? "filled" : "outlined"}
        onClick={() => setAllStatuses(!allSelected)}
        icon={allSelected ? <Cancel /> : <SelectAll />}
      />
      {DEVICE_GENERAL_STATUSES.map((status) => (
        <Chip
          key={status}
          label={
            <Box
              sx={{
                display: "flex",
                direction: "row",
                gap: 1,
              }}
            >
              {generalStatusToExplanation[status]}
              {countsByStatus && (
                <CountDisplay
                  isActive={activeStatuses.includes(status)}
                  number={countsByStatus[status]}
                />
              )}
            </Box>
          }
          variant={activeStatuses.includes(status) ? "filled" : "outlined"}
          onClick={() => toggleStatus(status)}
          icon={activeStatuses.includes(status) ? <Done /> : undefined}
        />
      ))}
    </Stack>
  );
};

export default DeviceGeneralStatusFilterChips;

import React, { useEffect } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useValidation } from "context/ValidationProvider";
import { useChainValidation } from "context/ChainValidationProvider";
import { detailImage, updateImage } from "queries/devices/images";
import LeftSidePanel from "./LeftSidePanel";
import RightSidePanel from "./RightSidePanel";
import { UpperPart, BottomButtons } from "./CustomComponents";

export type ImageEditorProps = {
  image_id?: string;
  data?: Blob;
  error_message?: string;
  created_at?: number;
};

type ImageDetails = {
  isVisible: boolean;
  isDark: boolean;
  isShiny: boolean;
  isBlurry: boolean;
  requiresCleaning: boolean;
  fullnessLevel: number;
  deviceId: string;
};

const ImageEditor: React.FC<ImageEditorProps> = ({ image_id, data, error_message, created_at }) => {
  const { onSave, onChangeVisibility } = useValidation();
  const { isChainValidation, nextValidation } = useChainValidation();

  // trigger rerender when ref size changes (simplest way to do this)
  // (either when full-screen is toggled or when window is resized)
  const [, forceUpdate] = React.useState<unknown>();

  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const defaultImageDetails = {
    isVisible: true,
    isDark: false,
    isShiny: false,
    isBlurry: false,
    requiresCleaning: false,
    fullnessLevel: 0,
  };

  const imageDetailQuery = useQuery({
    queryKey: ["detail-images", image_id!],
    queryFn: () => detailImage({ id: image_id! }),
  });

  const { data: INITIAL_IMAGE_DETAIL } = imageDetailQuery;

  const [imageDetails, setImageDetails] = React.useState<ImageDetails>({
    ...defaultImageDetails,
    deviceId: "",
  });

  useEffect(() => {
    if (INITIAL_IMAGE_DETAIL) {
      setImageDetails({
        isVisible: INITIAL_IMAGE_DETAIL.is_visible,
        isDark: INITIAL_IMAGE_DETAIL.is_dark,
        isShiny: INITIAL_IMAGE_DETAIL.is_shiny,
        isBlurry: INITIAL_IMAGE_DETAIL.is_blurry,
        requiresCleaning: INITIAL_IMAGE_DETAIL.requires_cleaning,
        fullnessLevel: (INITIAL_IMAGE_DETAIL.fullness_level ?? 0) * 100,
        deviceId: INITIAL_IMAGE_DETAIL.device,
      });
    }
  }, [INITIAL_IMAGE_DETAIL]);

  const updateImageDetail = useMutation({
    mutationFn: async (args: {
      isDark: boolean;
      isShiny: boolean;
      isBlurry: boolean;
      requiresCleaning: boolean;
      fullnessLevel: number;
    }) => {
      await updateImage({
        id: image_id!,
        body: {
          is_dark: args.isDark,
          is_shiny: args.isShiny,
          is_blurry: args.isBlurry,
          requires_cleaning: args.requiresCleaning,
          fullness_level: parseFloat((args.fullnessLevel / 100).toFixed(2)),
        },
      });
    },
  });

  const handleSave = () => {
    // Save the image details before any other action
    updateImageDetail.mutate({
      isDark: imageDetails.isDark,
      isShiny: imageDetails.isShiny,
      isBlurry: imageDetails.isBlurry,
      requiresCleaning: imageDetails.requiresCleaning,
      fullnessLevel: imageDetails.fullnessLevel,
    });

    if (
      imageDetails.isVisible &&
      (imageDetails.isDark || imageDetails.isShiny || imageDetails.isBlurry)
    ) {
      onChangeVisibility();
    } else if (imageDetails.isVisible) {
      onSave();
    } else {
      nextValidation();
    }
  };

  const handleReset = () => {
    setImageDetails({
      ...defaultImageDetails,
      deviceId: imageDetails.deviceId,
      isVisible: imageDetails.isVisible,
    });
    updateImageDetail.mutate(defaultImageDetails);
  };

  // listen on resized and update stage
  useEffect(() => {
    const fn = (e: Event) => {
      forceUpdate(e);
    };
    window.addEventListener("resize", fn);

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  // listen on fullscreen toggle and update stage
  useEffect(() => {
    forceUpdate({});
  }, [isFullscreen]);

  return (
    <Dialog
      open
      PaperProps={
        isFullscreen
          ? { style: { minHeight: "80%", minWidth: "70%", borderRadius: "16px" } }
          : { style: { minHeight: "70%", minWidth: "70%", borderRadius: "16px" } }
      }
    >
      <DialogContent>
        {/* upper part */}
        {!isFullscreen && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isChainValidation ? "space-between" : "flex-end",
            }}
          >
            <UpperPart />
          </Box>
        )}

        {/* main content */}
        <Box sx={{ display: "flex", height: "100%" }}>
          {/* left side */}
          <LeftSidePanel
            timestamp={created_at}
            isFullscreen={isFullscreen}
            toggleFullscreen={() => setIsFullscreen(!isFullscreen)}
            data={data}
            error_message={error_message}
            handleSave={handleSave}
            handleNotVisible={onChangeVisibility}
            isVisible={imageDetails.isVisible}
          />
          {/* right side */}
          {image_id && (
            <RightSidePanel
              isFullscreen={isFullscreen}
              imageDetails={imageDetails}
              onChange={(newDetails: Partial<ImageDetails>) =>
                setImageDetails((prev) => ({ ...prev, ...newDetails }))
              }
              handleReset={handleReset}
            />
          )}
        </Box>

        {/* bottom part */}
        {!isFullscreen && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
            <BottomButtons
              handleSave={handleSave}
              handleNotVisible={onChangeVisibility}
              isVisible={imageDetails.isVisible}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageEditor;

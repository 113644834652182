import axios from "axios";
import { ListResponse } from "../types";

export type ListLogItem = {
    id: string;
    device: {
        id: string;
        smapp_id: string;
        name: string;
    };
    created_at: number;
    filename: string;
}

export type ListLogParams = {
    page: number;
    page_size: number;
    device__id?: string;
    created_at__lte?: number;
    created_at__gte?: number;
}

export type ExportLogParams = {
    log_id: string;
}

export const listDeviceLogs = async (params: ListLogParams): Promise<ListResponse<ListLogItem>> => {
    const { data } = await axios.get<ListResponse<ListLogItem>>("/api/devices/logs/", { params: {  hr: true, ...params } });
    return data;
}

export const exportDeviceLog = async (params: ExportLogParams): Promise<Blob> => {
    const { data } = await axios.get(`/api/devices/logs/${params.log_id}/download`, { responseType: "blob" });
    return data;
}
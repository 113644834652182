import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import useDialog from "hooks/useDialog";
import InviteeTable from "pages/corpUserManagement/components/InviteeTable";
import InviteMaintainer from "pages/corpUserManagement/components/InviteUserModal";
import UserTable from "pages/userManagement/components/UserTable";
import useUserManagement from "pages/userManagement/main/useUserManagement";
import AppWrapper from "components/AppWrapper";
import ResetPasswordModal from "pages/userManagement/components/ResetPasswordModal";
import EditUserModal from "pages/userManagement/components/EditUserModal";
import useInviteManage from "./useInviteManage";

const CorpUserManagement = () => {
    const { jsx: theSnack } = useCustomSnackBar();
    const { isInviteDialogOpen, openInviteUserDialog, closeInviteUserDialog } = useDialog();
    const {
        onNameChange, listQuery, processedData, loadMore, totalCount,
        handleEditUser, handleUserPasswordReset, handleDeleteClick,
        showDeleteConfirm, handleConfirmDelete, handleCancelDelete,
        isDeleting, userToDelete,
        methods,
        corporations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        setSearchTerm,
        onUpdateSubmit,
        openEditModal,
        handleCloseEditModal,
        updateError,
        onResetPasswordSubmit,
        openUserPasswordResetModal,
        handleCloseUserPasswordResetModal,
        resetPasswordError,
    } = useUserManagement();

    const {
        invitations,
        error,
        sendInvitation,
        resendInvitation,
        isLoading,
        isSending,
        isReSending
    } = useInviteManage();

    return (
        <AppWrapper mainSxProps={{ width: "95%", maxWidth: "95%" }}>
            <Box>
                {theSnack}
                <Box sx={{ p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        User Management
                    </Typography>
                    <Button
                        variant="contained"
                        endIcon={<AddCircleOutlineIcon />}
                        onClick={openInviteUserDialog}
                    >
                        Invite user
                    </Button>
                </Box>
                <Box sx={{ marginBottom: "1em" }}>
                    <InviteeTable
                        isLoading={isLoading}
                        invitationData={invitations}
                        totalCount={invitations.length}
                        onInviteeEmailReset={resendInvitation}
                    />
                </Box>
                <Box>
                    <UserTable
                        onNameChange={onNameChange}
                        listQuery={listQuery}
                        processedData={processedData}
                        onLoadMore={loadMore}
                        totalCount={totalCount}
                        onEditUser={handleEditUser}
                        onUserPasswordReset={handleUserPasswordReset}
                        handleDeleteClick={handleDeleteClick}
                        showDeleteConfirm={showDeleteConfirm}
                        handleConfirmDelete={handleConfirmDelete}
                        handleCancelDelete={handleCancelDelete}
                        isDeleting={isDeleting}
                        userToDelete={userToDelete}
                    />
                </Box>
            </Box>
             <EditUserModal
                open={openEditModal}
                onClose={handleCloseEditModal}
                methods={methods}
                onSubmit={onUpdateSubmit}
                corporations={corporations}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage ?? false}
                isFetchingNextPage={isFetchingNextPage}
                setSearchTerm={setSearchTerm}
                updateError={updateError}
            />
            <ResetPasswordModal
                open={openUserPasswordResetModal}
                onClose={handleCloseUserPasswordResetModal}
                methods={methods}
                onSubmit={onResetPasswordSubmit}
                resetPasswordError={resetPasswordError}
            />
            <InviteMaintainer 
                isOpen={isInviteDialogOpen} 
                onClose={closeInviteUserDialog} 
                onSendInvitaion={sendInvitation} 
                corporations={corporations}
                setSearchTerm={setSearchTerm}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage  ?? false}
                isFetchingNextPage={isFetchingNextPage}
            />
        </AppWrapper>
    );
};

export default CorpUserManagement;

import React from "react";
import { Tooltip } from "@mui/material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// TODO colors

type CommissionStatusChipProps = {
  value?: string;
};
const CommissionStatusChip: React.FC<CommissionStatusChipProps> = ({ value }) => {
  return (
    <Tooltip title="Commission status" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <span style={{ fontSize: 14 }}>{value ?? "Unknown"}</span>
        <LocalShippingOutlinedIcon sx={{ fontSize: 16 }} />
      </div>
    </Tooltip>
  );
};

export default CommissionStatusChip;

import React from "react";
import { Tooltip } from "@mui/material";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
// TODO colors

type ConfigChipProps = {
  value?: { name: string; version: number } | null;
};
const ConfigChip: React.FC<ConfigChipProps> = ({ value }) => {
  const label = value?.name && value.version ? `${value.name} - ${value.version}` : "Unknown";

  return (
    <Tooltip title="Config" arrow>
      <div style={{ display: "flex", alignItems: "center", gap: "2px", color: "#606963" }}>
        <span style={{ fontSize: 14 }}>{label}</span>
        <IntegrationInstructionsOutlinedIcon sx={{ fontSize: 16 }} />
      </div>
    </Tooltip>
  );
};

export default ConfigChip;

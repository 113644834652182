import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type NotificationContextProps = {
    showNotification: (params: {
        severity: AlertColor;
        message?: string;
        autoHideDuration?: number;
    }) => void;
    closeNotification: () => void;
};

const INITIAL_VALUES: NotificationContextProps = {
    showNotification: () => { },
    closeNotification: () => { },
};

const defaultMessages: Record<AlertColor, string> = {
    success: "Operation was successful.",
    error: "An error occurred.",
    warning: "Warning: Please check the details.",
    info: "Here is some information.",
};

const NotificationContext = React.createContext<NotificationContextProps>(INITIAL_VALUES);

export const NotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [severity, setSeverity] = React.useState<AlertColor>("info");
    const [message, setMessage] = React.useState<string>("");
    const [autoHideDuration, setAutoHideDuration] = React.useState<number>(6000);



    const showNotification = React.useCallback((params: {
        severity: AlertColor;
        message?: string;
        autoHideDuration?: number;
    }) => {
        const { severity, message, autoHideDuration } = params;
        setSeverity(severity);
        setMessage(message || defaultMessages[severity]);
        setAutoHideDuration(autoHideDuration ?? 6000);
        setOpen(true);
    }, []);

    const closeNotification = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        closeNotification();
    };

    const contextValue = React.useMemo(
        () => ({
            showNotification,
            closeNotification,
        }),
        [showNotification, closeNotification]
    );

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
};

export const useNotification = () => React.useContext(NotificationContext);
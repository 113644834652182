import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient } from "@tanstack/react-query";
import { removeDevicesFromValidator } from "../../../queries/validators";
import { ReactComponent as TrapIcon } from "../../../icons/trap-icon-black.svg";

interface ValidatorItemProps {
  name: string;
  id: string;
  numberOfDevices: number;
  handleDeleteClick: () => void;
  handleAssignDevicesClick: () => void;
  handleSingleCheck: () => void;
  isChecked: boolean;
}

const ValidatorItem: React.FC<ValidatorItemProps> = ({
  name,
  id,
  numberOfDevices,
  handleDeleteClick,
  handleAssignDevicesClick,
  handleSingleCheck,
  isChecked,
}) => {
  const queryClient = useQueryClient();

  const [openRemoveValidatorDialog, setOpenRemoveValidatorDialog] = useState(false);
  const [openRemoveAssignedDevicesDialog, setOpenRemoveAssignedDevicesDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheck = () => {
    handleSingleCheck();
  };

  const handleOpenRemoveValidatorDialog = () => {
    setOpenRemoveValidatorDialog(true);
  };

  const handleCloseRemoveValidatorDialog = () => {
    setOpenRemoveValidatorDialog(false);
  };

  const handleConfirmValidatorDelete = () => {
    handleDeleteClick();
    setOpenRemoveValidatorDialog(false);
  };

  const handleOpenRemoveAssignedDevicesDialog = () => {
    setOpenRemoveAssignedDevicesDialog(true);
  };

  const handleCloseRemoveAssignedDevicesDialog = () => {
    setOpenRemoveAssignedDevicesDialog(false);
  };

  const handleConfirmAssignedDevicesDelete = async () => {
    setLoading(true);
    try {
      await removeDevicesFromValidator(id);
      queryClient.invalidateQueries({ queryKey: ["list-validators"] });
    } finally {
      setLoading(false);
      setOpenRemoveAssignedDevicesDialog(false);
    }
  };

  return (
    <>
      <TableRow
        sx={{
          height: 68,
          "& .MuiTableCell-root": {
            borderBottom: "1px solid #D4D7D5",
            fontSize: "16px",
          },
        }}
      >
        <TableCell sx={{ pl: 4 }}>{name}</TableCell>
        <TableCell sx={{ pl: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TrapIcon style={{ width: 24, height: 24 }} />
            <Box component="span" sx={{ ml: 1 }}>
              {numberOfDevices}
            </Box>
          </Box>
        </TableCell>
        <TableCell align="right" sx={{ pl: 4, pr: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Tooltip title="Remove all devices" arrow>
              <IconButton
                size="small"
                onClick={handleOpenRemoveAssignedDevicesDialog}
                sx={{
                  color: "#27382C",
                  border: "1px solid rgba(39, 56, 44, 0.2)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                  padding: "4px",
                  "&:hover": {
                    border: "1px solid #27382C",
                    backgroundColor: "rgba(39, 56, 44, 0.2)",
                  },
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Manage devices" arrow>
              <IconButton
                size="small"
                onClick={handleAssignDevicesClick}
                sx={{
                  color: "#27382C",
                  border: "1px solid rgba(39, 56, 44, 0.2)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                  padding: "4px",
                  "&:hover": {
                    border: "1px solid #27382C",
                    backgroundColor: "rgba(39, 56, 44, 0.2)",
                  },
                }}
              >
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Validator" arrow>
              <IconButton
                size="small"
                onClick={handleOpenRemoveValidatorDialog}
                sx={{
                  color: "#AB0B0B",
                  border: "1px solid rgba(171, 11, 11, 0.2)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                  padding: "4px",
                  "&:hover": {
                    border: "1px solid #AB0B0B",
                    backgroundColor: "rgba(171, 11, 11, 0.2)",
                  },
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell align="right" sx={{ pr: 4 }}>
          <Checkbox checked={isChecked} onChange={handleCheck} />
        </TableCell>
      </TableRow>

      {/* Confirmation Dialog */}
      <Dialog
        open={openRemoveValidatorDialog}
        onClose={handleCloseRemoveValidatorDialog}
        PaperProps={{
          style: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {name}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveValidatorDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmValidatorDelete} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemoveAssignedDevicesDialog}
        onClose={handleCloseRemoveAssignedDevicesDialog}
        PaperProps={{
          style: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle>Are you sure you want to remove all assigned devices for {name}?</DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveAssignedDevicesDialog} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmAssignedDevicesDelete}
            variant="contained"
            disabled={loading}
          >
            {loading ? "Removing..." : "Remove all"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValidatorItem;

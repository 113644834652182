import { Buffer } from "buffer";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

type GetAccessToken = () => string | undefined;
export const getAccessToken: GetAccessToken = () => Cookies.get("access");

type GetRefreshToken = () => string | undefined;
export const getRefreshToken: GetRefreshToken = () => Cookies.get("refresh");

export type GetTokens = () => {
  access: ReturnType<GetAccessToken>;
  refresh: ReturnType<GetRefreshToken>;
};
export const getTokens: GetTokens = () => ({
  access: getAccessToken(),
  refresh: getRefreshToken(),
});

const defaultCookieConfig = (decodedToken: DecodedToken): Cookies.CookieAttributes => {
  const { payload } = decodedToken;
  if (!payload) return {};
  return {
    expires: new Date(payload.exp * 1000),
  };
};

type SetAccessToken = (token: string, options?: Cookies.CookieAttributes) => void;
export const setAccessToken: SetAccessToken = (token, options) => Cookies.set("access", token, options);

type SetRefreshToken = (token: string, options?: Cookies.CookieAttributes) => void;
export const setRefreshToken: SetRefreshToken = (token, options) => Cookies.set("refresh", token, options);

type RemoveAccessToken = () => void;
const removeAccessToken: RemoveAccessToken = () => Cookies.remove("access");

type RemoveRefreshToken = () => void;
const removeRefreshToken: RemoveRefreshToken = () => Cookies.remove("refresh");

type RemoveTokens = () => void;
export const removeTokens: RemoveTokens = () => {
  removeAccessToken();
  removeRefreshToken();
};

type Payload = {
  exp: number;
  user_id: string;
  user: {
    name: string;
    type: "admin" | "validator" | "corporate" | "regular";
  };
};

export type DecodedToken<THeader = unknown, TPayload extends Payload = Payload> = {
  raw: string;
  header?: THeader;
  payload?: TPayload;
};

type Decode = (rawToken: string) => DecodedToken;
export const decode: Decode = (rawToken) => {
  let header;
  try {
    header = JSON.parse(Buffer.from(rawToken.split(".")[0], "base64").toString());
  } catch (e) {
    Sentry.captureException(
      new Error(`Failed to decode header of token, message: ${e}`, { cause: e })
    );
  }

  let payload;
  try {
    payload = JSON.parse(Buffer.from(rawToken.split(".")[1], "base64").toString());
  } catch (e) {
    Sentry.captureException(
      new Error(`Failed to decode payload of token, message: ${e}`, { cause: e })
    );
  }

  return {
    raw: rawToken,
    header,
    payload,
  };
};

type SetTokensProps = { access?: string; refresh?: string };
type SetTokens = (params: SetTokensProps) => void;
export const setTokens: SetTokens = ({ access, refresh }) => {
  if (access) {
    setAccessToken(access, defaultCookieConfig(decode(access)));
  } else {
    Sentry.captureException(new Error("setTokens called with no access token"));
  }
  if (refresh) {
    setRefreshToken(refresh, defaultCookieConfig(decode(refresh)));
  } else {
    Sentry.captureException(new Error("setTokens called with no refresh token"));
  }
};

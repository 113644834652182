import React from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  Button,
  Stack,
  IconButton,
  Alert,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from 'axios';
import { ListCorporationItem } from 'queries/corporations';
import Autocomplete from '@mui/material/Autocomplete';
import { USER_TYPE } from "queries/users";
import { useAuth } from "context/AuthProvider";

interface AddOrganisationModalProps {
  open: boolean;
  onClose: () => void;
  addCorporationError: AxiosError | null;
  setParentSearchTerm: (term: string) => void;
  parentCorporations: ListCorporationItem[];
  handleParentCorporationScroll: (event: React.SyntheticEvent) => void;
  isFetchingNextParentPage: boolean;
  isFetchingParentCorporations: boolean;
  handleAddModalSave: (data: { name: string; parentId: string | null; }, shouldManage: boolean) => Promise<void>;
  selectedCorporation: ListCorporationItem | null;
}

const AddOrganisationModal: React.FC<AddOrganisationModalProps> = ({
  open,
  onClose,
  addCorporationError,
  setParentSearchTerm,
  parentCorporations,
  handleParentCorporationScroll,
  isFetchingNextParentPage,
  isFetchingParentCorporations,
  handleAddModalSave,
  selectedCorporation,
}) => {
  const [corporationName, setCorporationName] = React.useState('');
  const [parentCorporationId, setParentCorporationId] = React.useState('');
  const { currentUser } = useAuth();

  React.useEffect(() => {
    if (!open) {
      setCorporationName('');
      setParentCorporationId('');
    } else if (selectedCorporation) {
      setParentCorporationId(selectedCorporation.id);
    }
  }, [open, selectedCorporation]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-corporation-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '30px',
        boxShadow: 24,
        p: 8,
      }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
          Add Organization
        </Typography>

        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Organization Name"
            value={corporationName}
            onChange={(e) => setCorporationName(e.target.value)}
            required
          />

          <FormControl 
            fullWidth 
            sx={{ mt: 2 }} 
          >
            <Autocomplete<ListCorporationItem>
              value={parentCorporations.find(corp => corp.id === parentCorporationId) || null}
              onChange={(_, newValue: ListCorporationItem | null) => setParentCorporationId(newValue?.id || '')}
              onInputChange={(_: React.SyntheticEvent, value: string) => setParentSearchTerm(value)}
              options={parentCorporations}
              getOptionLabel={(option: ListCorporationItem) => option.name}
              isOptionEqualToValue={(option: ListCorporationItem, value: ListCorporationItem) => option.id === value.id}
              noOptionsText={
                isFetchingParentCorporations ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1 }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : 'No options found'
              }
              renderInput={(params: React.ComponentProps<typeof TextField>) => (
                <TextField
                  {...params}
                  label="Parent Organization"
                  placeholder="Select parent organization"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                  required={currentUser && currentUser.type === USER_TYPE.MANAGER}
                />
              )}
              ListboxProps={{
                onScroll: handleParentCorporationScroll,
                style: { maxHeight: 300 }
              }}
              loading={isFetchingNextParentPage}
            />
          </FormControl>

          <Stack direction="column" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => 
              handleAddModalSave(
                { name: corporationName, parentId: parentCorporationId }, 
                false
              )
              }
              disabled={
                !corporationName || 
                (currentUser?.type === USER_TYPE.MANAGER && !parentCorporationId)
              }
            >
              Save & Close
            </Button>
            <Button
              variant="contained"
              onClick={() => handleAddModalSave({ name: corporationName, parentId: parentCorporationId }, true)}
              disabled={
                !corporationName || 
                (currentUser?.type === USER_TYPE.MANAGER && !parentCorporationId)
              }
            >
              Save & Manage
            </Button>
          </Stack>

          {addCorporationError !== null && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {(() => {
                switch (addCorporationError.response?.status) {
                  case 400:
                    return "Corporation with this name already exists";
                  case 500:
                    return "Server error, please try again later";
                  default:
                    return "Failed to create corporation";
                }
              })()}
            </Alert>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddOrganisationModal; 
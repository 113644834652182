import React from "react";
import { Typography } from "@mui/material";

type PestChipProps = {
  value?: string;
};

const PestChip: React.FC<PestChipProps> = ({ value }) => {
  return (
    <Typography 
      variant="body1" 
      fontSize={14}
      fontWeight={500} 
      sx={{ color: '#27382C' }}
    >
      {(value && value.trim() !== '') ? value : "Unknown pest"}
    </Typography>
  );
};

export default PestChip;

import React from "react";
import {
    Typography,
    Button,
    Alert,
    Container
} from "@mui/material";

type StartOrganizationProps = {
    handleStartClick: () => void
}


const StartOrganization: React.FC<StartOrganizationProps> = ({ handleStartClick }) => {
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                minHeight: "5em",
                bgcolor: "#ffff",
                padding: 4,
                borderRadius: 4,
                minWidth: "89vw"
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    textAlign: "left",
                    marginBottom: 2,
                    color: "#555",
                    fontWeight: "bold"
                }}
            >
                Start inviting and managing individuals to help you with trap installation.
                You will have full access to the devices they activate and you will gain
                access to management features.
            </Typography>

            <Alert
                severity="info"
                sx={{
                    bgcolor: "#FCE1CB",
                    color: "#4f4f4f",
                    borderRadius: 4,
                    fontSize: "0.9rem",
                    marginBottom: 3,
                    "& .MuiAlert-icon": {
                        color: 'warning.main',
                    }
                }}
            >
                We recommend using this feature if you have field workers assisting you in setting up traps.
            </Alert>

            <Button
                variant="contained"
                sx={{
                    alignSelf: "end"
                }}
                endIcon={<span style={{ fontSize: "1.2rem" }}>→</span>}
                onClick={handleStartClick}
            >
                Start
            </Button>
        </Container>
    );
};

export default StartOrganization;

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePickers from "pages/export/datePicker";
import LogTable from "./LogTable";

type LogExplorerDialogProps = {
  open: boolean;
  device_id: string;
  smapp_id: string;
  onClose: () => void;
};

const LogExplorerDialog = ({ open, device_id, smapp_id, onClose }: LogExplorerDialogProps) => {
  const [dateRange, setDateRange] = React.useState({
    fromDate: new Date(new Date().setDate(new Date().getDate() - 10)).toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
  });

  const handleDateChange = (date: { fromDate: string; toDate: string }) => {
    setDateRange(date);
  };

  const toLogDate = (date: string) => {
    const tmpDate = new Date(date);
    tmpDate.setDate(tmpDate.getDate() + 1);
    const newDate = tmpDate.getTime() / 1000;
    return newDate;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "16px",
          overflow: "hidden",
          minWidth: "70vw",
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DialogTitle>Export logs for {smapp_id}</DialogTitle>
        <DialogActions>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogActions>
      </div>
      <DialogContent>
        <DialogContentText>Please select the date range for the exported data.</DialogContentText>
        <br />
        <DatePickers onDateChange={handleDateChange} overrideDefaultDays={10} />
        <br />
        <LogTable
          device_id={device_id}
          fromDate={Math.floor(new Date(dateRange.fromDate).getTime() / 1000)}
          toDate={toLogDate(dateRange.toDate)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LogExplorerDialog;

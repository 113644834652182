import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  IMAGE_TYPES,
  ImageCoordinates,
  downloadImage,
  downloadSignedUrlImage,
} from "queries/devices/images";
import React from "react";
import { SECONDS_30 } from "utils/time";
import { Typography } from "@mui/material";
import ValidationProvider, { ValidationProviderProps } from "context/ValidationProvider";
import ImageEditor from "./ImageEditor";
import LoadingImage from "./LoadingImage";

export type ImageProps = {
  id: string;
  title?: string;
  initialCoordinates: ImageCoordinates;
  // The image edit has two modes: validation and delta
  // Validation mode is the one mostly used. It also marks images as validated and saves the
  // marked bounding boxes and count of bounding boxes (detection count).
  mode: "validation" | "delta";
  isVisible?: boolean;
  created_at?: number;
  gcimgs_url?: string;
} & Pick<ValidationProviderProps, "saveAs" | "onClose">;

export const ImageEdit: React.FC<ImageProps> = ({
  id,
  initialCoordinates,
  onClose,
  saveAs,
  title,
  mode,
  isVisible,
  created_at,
  gcimgs_url,
}) => {
  // always start with raw image, we draw coordinates on it, don't use processed image
  // for that as in that case we won't be able to delete bounding boxes.

  const queryOptions: UseQueryOptions<Blob, unknown> = gcimgs_url
    ? {
        queryFn: () => downloadSignedUrlImage({ url: gcimgs_url as string }),
        queryKey: ["download-image", { url: gcimgs_url }],
        staleTime: SECONDS_30,
        enabled: !!gcimgs_url,
        retry: false,
      }
    : {
        queryFn: () => downloadImage({ id: id as string, type: IMAGE_TYPES.raw }),
        queryKey: ["download-image", { id, type: IMAGE_TYPES.raw }],
        staleTime: Infinity,
        enabled: !!id,
        retry: false,
      };

  const { data, isFetching, error } = useQuery(queryOptions);

  let content = null;
  if (isFetching) {
    content = <LoadingImage showTitle={!!title} />;
  } else {
    content = (
      <ValidationProvider
        id={id}
        saveAs={saveAs}
        onClose={onClose}
        initialCoordinates={initialCoordinates}
        mode={mode}
        isVisible={isVisible}
      >
        <ImageEditor
          data={data}
          error_message={String(error)}
          created_at={created_at}
          image_id={id}
        />
      </ValidationProvider>
    );
  }

  return (
    <div className="image">
      {title && <Typography variant="overline">{title}</Typography>}
      {content}
    </div>
  );
};

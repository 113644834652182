import axios from "axios";
import ListResponse from "types/listResponse";


export type SendInviteParams = {
    email: string,
    user_type?: string,
    target_corporation?: string
}

type ResendInviteParams = {
    id: string
}

type AcceptInvitationParams = {
    token: string
}

type DetailInviteItem = {
    id: string,
    status: string
}
type InviteStatus = "pending" | "email_sent" | "email_resent" | "email_sent_failed" | "registered"

export type ListInviteItem = {
    id: string,
    email: string,
    status: InviteStatus,
    invitation_link: string
}

export const sendInvitation = async (params: SendInviteParams): Promise<DetailInviteItem> => {
    const { data } = await axios.post<DetailInviteItem>("/api/invite/", params);
    return data;
};

export const resendInvitation = async (params: ResendInviteParams): Promise<DetailInviteItem> => {
    const { data } = await axios.patch<DetailInviteItem>(`/api/invite/${params.id}/resend`);
    return data;
};

export const getInvitaionList = async (): Promise<ListResponse<ListInviteItem>> => {
    const { data } = await axios.get<ListResponse<ListInviteItem>>("/api/invite/");
    return data;
};

export const acceptInvitation = async (params: AcceptInvitationParams): Promise<DetailInviteItem> => {
    const { data } = await axios.patch<DetailInviteItem>("/api/invite/accept", params);
    return data;
}

export const getInvitaionForUser = async (email: string): Promise<ListResponse<ListInviteItem>> => {
    const { data } = await axios.get<ListResponse<ListInviteItem>>(`/api/invite/?email=${email}&status_not=registered`);
    return data;
};
import React from "react";
import { Layer, Stage } from "react-konva";
import URLImage from "./URLImage";

export type ImageReaderProps = {
  data: Blob | undefined;
  width: number;
  src: string;
};

const ImageReader: React.FC<ImageReaderProps> = ({ width, data, src }) => {
  return (
    <Stage width={width} height={300}>
      <Layer>
        <URLImage obj={data} src={src} />
      </Layer>
    </Stage>
  );
};

export default ImageReader;

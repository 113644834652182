import { Box, Card, TextField, Typography, IconButton, useMediaQuery, Theme, Tooltip, CircularProgress, Alert } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useRef, useState } from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ListInviteItem } from "queries/inviteUser";
import InfoIcon from '@mui/icons-material/Info';

type InviteeTableProps = {
    isLoading: boolean;
    invitationData: ListInviteItem[];
    totalCount: number;
    onInviteeEmailReset: (invitee: any) => void;
};

const InviteeTable: React.FC<InviteeTableProps> = ({
    isLoading,
    invitationData,
    totalCount,
    onInviteeEmailReset,
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const statusDict = {
        pending: "Pending",
        email_sent: "Email sent",
        email_resent: "Email resent",
        email_sent_failed: "Email sent failed",
        registered: "Registered"
    }
    const processedData = invitationData.map(item => ({
        ...item,
        status: statusDict[item.status] || item.status,
    }));

    const filteredData = processedData.filter(item =>
        item.email.toLowerCase().includes(searchTerm.toLowerCase()) || item.status !== "registered"
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const columns: GridColDef[] = [
        { field: "email", headerName: "EMAIL", flex: 1 },
        { field: "status", headerName: "STATUS", flex: 1, minWidth: 70, },
        {
            field: "actions",
            headerName: "",
            flex: 0.25,
            minWidth: 70,
            sortable: false,
            renderCell: (params: any) => (
                <>
                    <Tooltip title="Copy invitation link" arrow>
                        <IconButton
                            size="small"
                            onClick={() => {
                                navigator.clipboard.writeText(params.row.invitation_link)
                            }}
                            sx={{
                                color: "#27382C",
                                border: "1px solid rgba(39, 56, 44, 0.2)",
                                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                                padding: "4px",
                                marginRight: "20px",
                                "&:hover": {
                                    border: "1px solid #27382C",
                                    backgroundColor: "rgba(39, 56, 44, 0.2)",
                                },
                            }}
                        >
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Resend email" arrow>
                        <IconButton
                            size="small"
                            onClick={() => onInviteeEmailReset(params.row)}
                            sx={{
                                color: "#27382C",
                                border: "1px solid rgba(39, 56, 44, 0.2)",
                                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                                padding: "4px",
                                "&:hover": {
                                    border: "1px solid #27382C",
                                    backgroundColor: "rgba(39, 56, 44, 0.2)",
                                },
                            }}
                        >
                            <ReplayIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>

                </>
            )
        },
    ];

    const getDataGridOrAlert = (hasDataToShow: boolean) => {
        if (hasDataToShow) {
            return <DataGrid
                sx={{
                    fontFamily: "Lexend",
                    height: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                        bgcolor: "#FFFFFF",
                        borderBottom: "1px solid #D4D7D5",
                        "& .MuiDataGrid-columnHeaderTitle": {
                            color: "#D4D7D5",
                            fontWeight: "bold",
                            fontSize: "14px",
                        },
                    },
                    border: "none",
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none"
                    }
                }}
                rows={filteredData}
                columns={columns}
                getRowId={(row) => row.id}
                disableRowSelectionOnClick
                disableColumnMenu
            />
        }
        return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Alert
                severity="info"
                sx={{
                    marginTop: "3em",
                    display: "inline-flex",
                    width: "auto",
                    borderRadius: "50px",
                    padding: "10px 20px",
                    backgroundColor: "#EAEBEA",
                    color: "#27382C",
                    "& .MuiAlert-icon":
                        { color: "#27382C" }
                }}
            >
                No invited users found. There is no data to display at the moment.
            </Alert>
        </Box>

    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
            <Card sx={{ borderRadius: 6, overflow: "hidden", bgcolor: "#FFFFFF", paddingTop: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, flexGrow: 1, margin: 2, justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            fontSize="18px"
                            sx={{ color: "#27382C" }}
                        >
                            Invited users
                        </Typography>
                        <Tooltip title="Invited users who haven't registered yet are listed here. Registered users appear in the Users table." arrow>
                            <IconButton sx={{ color: "#27382C", padding: 0 }}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <TextField
                        sx={{ width: "20em", pl: 1, borderRadius: "12px" }}
                        label="Search Email..."
                        variant="outlined"
                        size="small"
                        onChange={handleSearchChange}
                        value={searchTerm}
                    />
                </Box>
                <Box ref={scrollRef} sx={{ minHeight: "30vh", paddingLeft: "2em", paddingRight: "2em" }}>
                    {!isLoading && getDataGridOrAlert(filteredData.length > 0)}
                    {isLoading && (
                        <Box sx={{ display: "flex", justifyContent: "center", p: 2, borderTop: "1px solid #D4D7D5" }}>
                            <CircularProgress size={24} />
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default InviteeTable;

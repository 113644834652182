import React from "react";
import { Tooltip } from "@mui/material";
import OpacityOutlinedIcon from "@mui/icons-material/OpacityOutlined";
import { isNotNil } from "utils/lodash";

// TODO: colors
type HumidityChipProps = {
  value?: number | null;
};
const HumidityChip: React.FC<HumidityChipProps> = ({ value }) => {
  return (
    <Tooltip title="Humidity" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <OpacityOutlinedIcon sx={{ fontSize: 16 }} />
        <span style={{ fontSize: 14 }}>{isNotNil(value) ? `${value.toFixed(2)}%` : "Unknown"}</span>
      </div>
    </Tooltip>
  );
};

export default HumidityChip;

import React from "react";
import { Tooltip } from "@mui/material";
import convert from "convert";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import { TEMPERATURE, useUserPreferences } from "context/UserPreferencesProvider";
import { isNotNil } from "utils/lodash";

// TODO: colors
type TemperatureChipProps = {
  value?: number | null;
};
const TemperatureChip: React.FC<TemperatureChipProps> = ({ value }) => {
  const { temperature } = useUserPreferences();
  let label = "Unknown";
  if (isNotNil(value)) {
    if (temperature === TEMPERATURE.CELSIUS) label = `${value.toFixed(2)} ℃`;
    else if (temperature === TEMPERATURE.FAHRENHEIT)
      label = `${convert(value, "celsius").to("fahrenheit").toFixed(2)} ℉`;
  }
  return (
    <Tooltip title="Temperature" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <ThermostatOutlinedIcon sx={{ fontSize: 16 }} />
        <span style={{ fontSize: 14 }}>{label}</span>
      </div>
    </Tooltip>
  );
};

export default TemperatureChip;

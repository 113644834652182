import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppWrapper from "components/AppWrapper";
import { Grid, Stack, Typography, Autocomplete, TextField, CircularProgress, Box, Alert } from "@mui/material";
import DeviceStatusDonutChartPanel from "components/visualization/DeviceStatusDonutChartPanel";
import DetectionCountDeltaChartPanel from "components/visualization/DetectionCountDeltaChartPanel";
import useDialog from "hooks/useDialog";
import useDashboard from "./useDashboard";
import WrongEmailModal from "../components/WrongLoginEmailModal";

const DashboardMain: React.FC = () => {
  const {
    generalChartData,
    detailedChartData,
    numberOfDevices,
    firstName,
    onCorporationChange,
    isAdmin,
    corporations,
    selectedCorporation,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    handleInputChange,
    hasPendingInvitation
  } = useDashboard();

  const { isInviteDialogOpen: isModalOpen, openInviteUserDialog, closeInviteUserDialog } = useDialog();

  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  const location = useLocation();
  const locationState = location.state || {};

  useEffect(() => {
    if (locationState.isInviteAccepted === false && !isModalShown) {
      openInviteUserDialog();
      setIsModalShown(true);
    }

  }, [locationState.isInviteAccepted, isModalShown, openInviteUserDialog])

  const handleScroll = (event: React.SyntheticEvent) => {
    const element = event.currentTarget;
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 50 && // 50px before bottom
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  return (
    <AppWrapper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pt: 2, pl: 4, pr: 4, gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, width: "100%" }}>
          <Typography variant="h4" component="h1">
            Welcome {firstName}!
          </Typography>
          {hasPendingInvitation && (
            <Alert variant="filled" severity="warning" >
              You have a pending invitation. Please go to your email account to accept it.
            </Alert>
          )}
          {locationState && (
            <WrongEmailModal isOpen={isModalOpen} onClose={closeInviteUserDialog} />
          )}
        </Box>
        {isAdmin && (
          <Autocomplete
            value={selectedCorporation}
            onChange={onCorporationChange}
            onInputChange={handleInputChange}
            options={corporations?.data || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select corporation"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    fontSize: '0.875rem',
                    padding: '0.2rem 0.75rem',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out',
                    border: '0px solid #ffffff',
                    '& fieldset': {
                      border: 'none'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&:hover': {
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                    },
                    '&.Mui-focused fieldset': {
                      boxShadow: '0 0 0 2px rgba(243, 181, 26, 0.3)',
                    },
                  },
                  minWidth: 220,
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScroll,
              style: { maxHeight: 300 }
            }}
            loading={isFetchingNextPage}
          />
        )}
      </Stack>
      <Stack
        spacing={2}
        sx={{
          width: '96%',
          mx: 'auto',
          py: 2,
          overflow: 'auto'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DeviceStatusDonutChartPanel
              title="Detailed Device Status Distribution"
              data={detailedChartData}
              numberOfDevices={numberOfDevices}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DeviceStatusDonutChartPanel
              title="General Device Status Distribution"
              data={generalChartData}
            />
          </Grid>
        </Grid>
        <DetectionCountDeltaChartPanel corporationId={selectedCorporation?.id} />
      </Stack>
    </AppWrapper>
  );
};

export default DashboardMain;

import React from "react";
import { Tooltip } from "@mui/material";
import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerOutlined";
import { isNotNil } from "utils/lodash";

// TODO: colors
type SolarCellVoltageChipProps = {
  value?: number | null;
};
const SolarCellVoltageChip: React.FC<SolarCellVoltageChipProps> = ({ value }) => {
  return (
    <Tooltip title="Solar cell voltage" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <SolarPowerOutlinedIcon sx={{ fontSize: 16 }} />
        <span style={{ fontSize: 14 }}>{isNotNil(value) ? `${value.toFixed(2)} V` : "Unknown"}</span>
      </div>
    </Tooltip>
  );
};

export default SolarCellVoltageChip;

import { useState, useEffect, useMemo, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useAuth } from 'context/AuthProvider';
import {
    listCorporations,
    createCorporation,
    updateCorporation,
    deleteCorporation,
    getCorporationById,
    ListCorporationItem,
    ListCorporationParams
} from '../../../queries/corporations';

const useOrganisationManagement = () => {
    const queryClient = useQueryClient();

    const { currentUser } = useAuth();

    const [addCorporationError, setAddCorporationError] = useState<AxiosError | null>(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
    const [isManageModalOpen, setIsManageModalOpen] = useState(false);
    const [selectedCorporation, setSelectedCorporation] = useState<ListCorporationItem | null>(null);
    const [manageCorporationError, setManageCorporationError] = useState<string | null>(null);
    const [areMoreParentCorporations, setAreMoreParentCorporations] = useState(false);
    const [parentSearchTerm, setParentSearchTerm] = useState('');
    const [parentCorporation, setParentCorporation] = useState<ListCorporationItem | null>(null);
    const PAGE_SIZE = 20;
    const [isLoadingParentCorporation, setIsLoadingParentCorporation] = useState(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

    const debouncedSetSearch = useMemo(
        () =>
            debounce((query: string) => {
                setDebouncedSearchQuery(query);
            }, 300),
        []
    );

    useEffect(() => {
        debouncedSetSearch(searchQuery);
        return () => {
            debouncedSetSearch.cancel();
        };
    }, [searchQuery, debouncedSetSearch]);

    const fetchCorporations = useCallback(async (params: ListCorporationParams) => {
        try {
            params.page_size = PAGE_SIZE;
            const response = await listCorporations(params);
            return {
                corporations: response.data,
                nextPage: response.meta.count > params.page * PAGE_SIZE ? params.page + 1 : null,
                total: response.meta.count,
            };
        } catch (error) {
            console.error("Error fetching corporations:", error);
            throw error;
        }
    }, []);

    const {
        data: corporationsPages,
        isLoading,
        isError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["corporations", debouncedSearchQuery] as const,
        queryFn: ({ pageParam = 1 }) =>
            fetchCorporations({
                page: pageParam,
                search: debouncedSearchQuery,
                ...(currentUser?.corporation == null
                    ? { parent_isnull: true }
                    : { id__in: [currentUser!.corporation] }),
            }),
        getNextPageParam: (lastPage) => lastPage.nextPage,
        enabled: !!currentUser
    });

    const corporations = {
        data: corporationsPages?.pages.flatMap((page) => page.corporations) ?? [],
    };

    useEffect(() => {
        if (corporations.data?.[0]) {
            const hasParentCorporations = corporations.data.some(corp =>
                corp.parent_id === null
            );
            setAreMoreParentCorporations(hasParentCorporations);
        }
    }, [corporations.data]);

    useEffect(() => {
        if (!isAddModalOpen) {
            setAddCorporationError(null);
            setSelectedCorporation(null);
        }
    }, [isAddModalOpen]);

    useEffect(() => {
        if (!isManageModalOpen) {
            setManageCorporationError(null);
            setSelectedCorporation(null);
            setParentCorporation(null);
            queryClient.removeQueries({ queryKey: ['devices'] });
            queryClient.removeQueries({ queryKey: ['assignedDevices'] });
        }
    }, [isManageModalOpen, queryClient]);

    const handleAddCorporation = async (data: { name: string; parentId: string | null }, shouldManage: boolean) => {
        try {
            const newCorporation = await createCorporation({
                name: data.name,
                parent_id: data.parentId
            });

            if (shouldManage) {
                setIsManageModalOpen(true);
                setSelectedCorporation(newCorporation);
            } else {
                setIsAddModalOpen(false);
                queryClient.invalidateQueries({ queryKey: ['corporations'] });
            }

            if (data.parentId === null) {
                setAreMoreParentCorporations(false);
            }
        } catch (err) {
            setAddCorporationError(err instanceof AxiosError ? err : null);
        }
    };

    const updateMutation = useMutation<any, AxiosError, any, any>({
        mutationFn: updateCorporation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['corporations'] });
            setIsUpdateSuccess(true);
            setTimeout(() => setIsUpdateSuccess(false), 2500);
        },
        onError: (error) => {
            setManageCorporationError(error.message);
            setIsUpdateSuccess(false);
            setTimeout(() => setManageCorporationError(null), 2500);
        },
    });

    const updateCorporationSubmit = async (data: { name: string; parentId: string | null }) => {
        const params: { name: string; parent_id?: string | null } = {
            name: data.name,
        };

        if (data.parentId) {
            params.parent_id = data.parentId;
        } else {
            params.parent_id = null;
        }

        updateMutation.mutate({
            id: selectedCorporation?.id,
            params
        });
    };

    const handleSearchChange = (newQuery: string) => {
        setSearchQuery(newQuery);
    };

    const {
        data: parentCorporationsPages,
        fetchNextPage: fetchNextParentPage,
        hasNextPage: hasNextParentPage,
        isFetchingNextPage: isFetchingNextParentPage,
        isFetching: isFetchingParentCorporations
    } = useInfiniteQuery({
        queryKey: ["parentCorporations", parentSearchTerm] as const,
        queryFn: ({ pageParam = 1 }) => fetchCorporations({ page: pageParam, search: parentSearchTerm }),
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const parentCorporations = parentCorporationsPages?.pages.flatMap((page) => page.corporations) ?? [];

    const handleParentCorporationScroll = (event: React.SyntheticEvent) => {
        const element = event.currentTarget;
        if (
            element.scrollTop + element.clientHeight >= element.scrollHeight - 50 &&
            hasNextParentPage &&
            !isFetchingNextParentPage
        ) {
            fetchNextParentPage();
        }
    };

    const deleteMutation = useMutation({
        mutationFn: deleteCorporation,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['corporations'] });
            queryClient.invalidateQueries({ queryKey: ['parentCorporations'] });
        },
    });

    const handleDeleteCorporation = (id: string) => {
        deleteMutation.mutate(id);
    };

    useEffect(() => {
        const fetchParentCorporation = async () => {
            if (selectedCorporation?.parent_id) {
                setIsLoadingParentCorporation(true);
                try {
                    const parent = await getCorporationById(selectedCorporation.parent_id);
                    setParentCorporation(parent);
                } catch (error) {
                    setParentCorporation(null);
                } finally {
                    setIsLoadingParentCorporation(false);
                }
            } else {
                setParentCorporation(null);
            }
        };

        fetchParentCorporation();
    }, [selectedCorporation]);

    return {
        corporations: corporations.data,
        areMoreParentCorporations,
        loading: isLoading,
        error: isError ? 'Failed to fetch corporations' : null,
        isAddModalOpen,
        setIsAddModalOpen,
        addCorporationError,
        handleAddCorporation,
        fetchCorporations: fetchNextPage,
        searchQuery,
        setSearchQuery: handleSearchChange,
        isManageModalOpen,
        setIsManageModalOpen,
        selectedCorporation,
        setSelectedCorporation,
        updateCorporationSubmit,
        manageCorporationError,
        hasMorePages: hasNextPage ?? false,
        loadMore: fetchNextPage,
        isLoadingMore: isFetchingNextPage,
        setParentSearchTerm,
        parentCorporations,
        parentCorporation,
        isFetchingParentCorporations,
        handleParentCorporationScroll,
        isFetchingNextParentPage,
        handleDeleteCorporation,
        isLoadingParentCorporation,
        isDeleting: deleteMutation.isLoading,
        isUpdateSuccess,
    };
};

export default useOrganisationManagement;
import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { listDeviceLogs, exportDeviceLog, ListLogParams } from "queries/devices/logExports";
import useInfiniteScroll from "react-infinite-scroll-hook";

const INFINITE_PAGE_SIZE = 5;

const fetchDeviceLogs = async ({
  pageParam = 1,
  deviceId,
  fromDate,
  toDate,
}: {
  pageParam?: number;
  deviceId?: string;
  fromDate: number;
  toDate: number;
}) => {
  const listLogParams: ListLogParams = {
    page: pageParam,
    page_size: INFINITE_PAGE_SIZE,
    device__id: deviceId,
    created_at__gte: fromDate,
    created_at__lte: toDate,
  };
  const logs = await listDeviceLogs(listLogParams);
  return logs;
};

type LogTableProps = {
  device_id?: string;
  fromDate: number;
  toDate: number;
};

const LogTable = ({ device_id, fromDate, toDate }: LogTableProps) => {
  const {
    data: logs,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: ["device-logs", device_id, fromDate, toDate],
    queryFn: ({ pageParam = 1 }) =>
      fetchDeviceLogs({ pageParam, deviceId: device_id, fromDate, toDate }),
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    keepPreviousData: false,
  });

  const logData = logs?.pages.flatMap((page) => page.data) || [];

  const [scrollRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: Boolean(hasNextPage),
    onLoadMore: fetchNextPage,
    disabled: Boolean(error),
    rootMargin: "0px 0px 1000px 0px",
  });

  const openLogInBrowser = async (logID: string) => {
    const blob = await exportDeviceLog({ log_id: logID });
    const text = await blob.text();
    const url = URL.createObjectURL(new Blob([text], { type: "text/plain" }));
    window.open(url, "_blank");
  };

  const downloadLog = async (smapp_id: string, date: number, logID: string) => {
    const blob = await exportDeviceLog({ log_id: logID });
    const url = URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${smapp_id}_${date}.log`);
    tempLink.click();
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "16px",
        backgroundColor: "#FFF",
        pt: 1,
        pb: 4,
      }}
    >
      <Box
        sx={{
          height: 68,
          backgroundColor: "#FFF",
          display: "flex",
          alignItems: "center",
          pl: 5,
        }}
      >
        <Typography variant="body1" fontWeight="bold" fontSize="18px">
          DEVICE LOGS
        </Typography>
      </Box>

      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "50vh",
          mx: 3,
        }}
      >
        {logData.map((row) => (
          <Paper
            key={row.id}
            elevation={0}
            sx={{
              display: "flex",
              bgcolor: "#FFF",
              height: 70,
              borderBottom: "1px solid #D4D7D5",
              borderRadius: 0,
            }}
            ref={scrollRef}
          >
            <Box sx={{ flex: 1, pl: 2, display: "flex", alignItems: "center" }}>
              <Typography variant="body1" fontSize="16px">
                {row.device.smapp_id}
              </Typography>
            </Box>
            <Box sx={{ flex: 3, pl: 4, display: "flex", alignItems: "center" }}>
              <Typography variant="body1" fontSize="16px">
                {new Date(row.created_at * 1000).toLocaleDateString()}
              </Typography>
            </Box>
            <Box sx={{ flex: 1, pl: 4, display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                onClick={() => openLogInBrowser(row.id)}
                sx={{ whiteSpace: "nowrap" }}
              >
                Open in browser
              </Button>
            </Box>
            <Box sx={{ flex: 1, pl: 4, display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                onClick={() => downloadLog(row.device.smapp_id, row.created_at, row.id)}
              >
                Download
              </Button>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default LogTable;

import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHoriz";
import ArchiveIcon from "@mui/icons-material/Archive";
import VerifiedIcon from "@mui/icons-material/Verified";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useMutation, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import ROUTES from "routes";
import { archiveDevices } from "queries/devices/archive";
import ArchiveDeviceDialog from "./ArchiveDeviceDialog";
import LogExplorerDialog from "./LogExplorerDialog";

type DeviceMoreOptionsButtonProps = {
  id: string;
  isAdmin: boolean;
  smapp_id: string;
  setErrorList?: React.Dispatch<React.SetStateAction<string[]>>;
  setSuccessList?: React.Dispatch<React.SetStateAction<string[]>>;
  canViewLogs: boolean;
  onClick: () => void;
  onClose: () => void;
};

const DeviceMoreOptionsButton: React.FC<DeviceMoreOptionsButtonProps> = ({
  id,
  smapp_id,
  isAdmin,
  setErrorList,
  setSuccessList,
  canViewLogs,
  onClick,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [isArchiving, setArchiving] = useState(false);
  const [isLogDialogOpen, setLogDialogOpen] = useState(false);

  const queryClient = useQueryClient();
  const archiveDeviceMutation = useMutation({
    mutationFn: archiveDevices,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["inf-list-devices"] });
      setSuccessList?.((pre) => [...pre, "Archiving succeeded"]);
    },
    onError: () => {
      setErrorList?.((pre) => [...pre, "Archiving failed"]);
      setArchiveDialogOpen(false);
    },
    onSettled: () => {
      setArchiving(false);
      console.log("Archiving settled");
    },
  });

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleArchiveClick = () => {
    handleMenuClose();
    setArchiveDialogOpen(true);
    onClick();
  };

  const handleLogDialogClose = () => {
    setLogDialogOpen(false);
    onClose();
  };

  const handleArchiveDialogClose = () => {
    setArchiveDialogOpen(false);
    onClose();
  };

  const handleConfirm = () => {
    setArchiving(true);
    archiveDeviceMutation.mutate({
      devices_to_archive: [id],
    });
  };

  const handleBackdropClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleMenuClose();
    onClose();
  };

  const handleLogClick = async () => {
    handleMenuClose();
    setLogDialogOpen(true);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="device-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        size="small"
        sx={{
          marginRight: "-4px",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="device-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleBackdropClick}
        PaperProps={{
          sx: {
            borderRadius: 2,
            mt: 1,
            "& .MuiMenuItem-root": {
              px: 2,
              py: 1,
              gap: 1.5,
            },
          },
          onClick: (e) => e.stopPropagation(),
        }}
        slotProps={{
          backdrop: {
            onClick: handleBackdropClick,
          },
        }}
      >
        <MenuItem onClick={handleArchiveClick}>
          <ArchiveIcon fontSize="small" />
          Archive
        </MenuItem>
        {canViewLogs && (
          <div>
            <Divider />
            <MenuItem onClick={handleLogClick}>
              <FileDownloadIcon fontSize="small" />
              Export Logs
            </MenuItem>
          </div>
        )}
        {isAdmin && (
          <div>
            <Divider />
            <MenuItem
              component={Link}
              to={`${ROUTES.VALIDATOR_DEVICES}/${id}`}
              onClick={handleMenuClose}
            >
              <VerifiedIcon fontSize="small" />
              Validate
            </MenuItem>
          </div>
        )}
      </Menu>
      <ArchiveDeviceDialog
        open={isArchiveDialogOpen}
        smapp_id={smapp_id}
        onClose={handleArchiveDialogClose}
        onConfirm={handleConfirm}
        isArchiving={isArchiving}
      />
      <LogExplorerDialog
        open={isLogDialogOpen}
        device_id={id}
        smapp_id={smapp_id}
        onClose={handleLogDialogClose}
      />
    </>
  );
};

export default DeviceMoreOptionsButton;

import React from "react";
import { Tooltip } from "@mui/material";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import { isNotNil } from "utils/lodash";
import { formatDistance } from "date-fns";
// TODO colors

type LastSyncChipProps = {
  value?: number | null;
};
const LastSyncChip: React.FC<LastSyncChipProps> = ({ value }) => {
  return (
    <Tooltip title="Last sync" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <CloudSyncOutlinedIcon sx={{ fontSize: 16 }} />
        <span style={{ fontSize: 14 }}>
          {isNotNil(value)
            ? formatDistance(new Date(value * 1000), new Date(), {
                addSuffix: true,
              })
            : "Unknown"}
        </span>
      </div>
    </Tooltip>
  );
};

export default LastSyncChip;

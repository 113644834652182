import { useEffect, useState } from "react";

interface Validator {
  uuid: string;
}

const useValidatorSelection = (validators: Validator[]) => {
  const [validatorsChecked, setValidatorsChecked] = useState<
    { validatorId: string; isChecked: boolean }[]
  >([]);

  useEffect(() => {
    setValidatorsChecked((prev) => {
      const prevMap = new Map(prev.map((v) => [v.validatorId, v.isChecked]));
      return validators.map((v) => ({
        validatorId: v.uuid,
        isChecked: prevMap.get(v.uuid) ?? false,
      }));
    });
  }, [validators]);

  const checked = validatorsChecked.length > 0 && validatorsChecked.every((v) => v.isChecked);

  const someChecked = validatorsChecked.some((v) => v.isChecked && !checked);

  const checkedValidators = validatorsChecked
    .filter((v) => v.isChecked)
    .map((v) => v.validatorId);

  const toggleValidatorCheck = (validatorId: string) => {
    setValidatorsChecked((prev) =>
      prev.map((v) =>
        v.validatorId === validatorId ? { ...v, isChecked: !v.isChecked } : v
      )
    );
  };

  const toggleCheckAll = () => {
    const newChecked = !checked;
    setValidatorsChecked((prev) =>
      prev.map((v) => ({ ...v, isChecked: newChecked }))
    );
  };

  return { checked, someChecked, validatorsChecked, toggleValidatorCheck, toggleCheckAll, checkedValidators };
};

export default useValidatorSelection;

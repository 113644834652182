import React from "react";
import { Tooltip } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { isNotNil } from "utils/lodash";
// TODO colors

type AreaChipProps = {
  country: string | null;
  city: string | null;
};

const AreaChip: React.FC<AreaChipProps> = ({ country, city }) => {
  const label = [country, city]
    .filter(value => isNotNil(value) && value.trim() !== '')
    .join(" - ") || "Unknown";
  return (
    <Tooltip title="Area" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <span style={{ fontSize: 14 }}>{label}</span>
        <LocationOnOutlinedIcon sx={{ fontSize: 16 }} />
      </div>
    </Tooltip>
  );
};

export default AreaChip;

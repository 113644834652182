import React from "react";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import { useValidation } from "context/ValidationProvider";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";
import ImageEditorStage from "./ImageEditorStage";
import { UpperPart, BottomButtons } from "./CustomComponents";

export type LeftSidePanelProps = {
  timestamp?: number;
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  data?: Blob;
  error_message?: string;
  handleSave: () => void;
  handleNotVisible: () => void;
  isVisible?: boolean;
};

const LeftSidePanel: React.FC<LeftSidePanelProps> = ({
  timestamp,
  isFullscreen,
  toggleFullscreen,
  data,
  error_message,
  handleSave,
  handleNotVisible,
  isVisible,
}) => {
  const {
    annotations,
    onClear,
    coordinateIndexToDelete,
    deleteSelectedCoordinate,
    onResetDeltaCount,
  } = useValidation();

  const ref = React.useRef<HTMLDivElement>(null);
  const width = ref.current?.offsetWidth ?? 0;

  return (
    <Box
      sx={{
        width: isFullscreen ? "100%" : "60%",
        height: "100%",
        mr: "10px",
      }}
      ref={ref}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" fontWeight="medium" sx={{ marginBottom: "16px" }}>
          Edit labeling
        </Typography>
        {isFullscreen && <UpperPart />}
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px",
          }}
        >
          {timestamp ?? true ? (
            <Box sx={{ fontSize: "large" }}>
              {new Date(timestamp! * 1000).toLocaleString("en-US")}
            </Box>
          ) : (
            <Box sx={{ fontSize: "medium" }}>No timestamp</Box>
          )}

          <Tooltip title="Moth counter" arrow placement="top">
            <Typography
              variant="h6"
              fontWeight="medium"
              sx={{
                marginBottom: "16px",
                borderRadius: "8px",
                border: "2px solid #000",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              {annotations.length}
            </Typography>
          </Tooltip>

          <Button aria-label="fullscreen" onClick={toggleFullscreen} size="large" color="inherit">
            {!isFullscreen ? (
              <Box
                sx={{
                  color: "#F4B71D",
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "large",
                }}
              >
                <FullscreenRoundedIcon fontSize="large" />
                Full screen
              </Box>
            ) : (
              <Box
                sx={{
                  color: "#F4B71D",
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "large",
                }}
              >
                <FullscreenExitRoundedIcon fontSize="large" />
                Exit full screen
              </Box>
            )}
          </Button>
        </Box>
      </Box>
      <ImageEditorStage data={data} error_message={error_message} width={width} />
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box>
          <Button variant="outlined" size="large" sx={{ m: 1 }} onClick={onResetDeltaCount}>
            Reset delta catch count
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            <Button
              variant="outlined"
              size="large"
              sx={{ m: 1 }}
              onClick={() => {
                deleteSelectedCoordinate();
              }}
              disabled={coordinateIndexToDelete === null}
              aria-disabled={coordinateIndexToDelete === null}
            >
              Remove selected
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              size="large"
              sx={{ m: 1 }}
              onClick={onClear}
              disabled={annotations.length === 0}
            >
              Clear all
            </Button>
          </Box>
          {isFullscreen && (
            <BottomButtons
              handleSave={handleSave}
              handleNotVisible={handleNotVisible}
              isVisible={isVisible}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSidePanel;

import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Box, Typography, Grid } from "@mui/material";
import { ListDeviceParams, listDeviceCards } from "queries/devices";
import { FlexLoader, Loader } from "components/Loader";
import { flatten } from "lodash";
import DeviceCard, { DeviceCardProps } from "components/DeviceCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import useDeviceFilters from "hooks/useDeviceFilters";
import { generalStatusesToDetailedStatuses } from "utils/device-statuses";

const INFINITE_PAGE_SIZE = 20;

interface Props {
  search: string | null;
}

function DevicesList({ search }: Props) {
  const { nonEmptyFilters, activeStatuses } = useDeviceFilters();

  const { jsx: theSnack, setErrorList, setSuccessList } = useCustomSnackBar();

  const {
    data: infData,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["inf-list-devices", nonEmptyFilters, activeStatuses, search],
    queryFn: async ({ pageParam = 1 }) => {
      const listDeviceParams: ListDeviceParams = {
        page: pageParam,
        page_size: INFINITE_PAGE_SIZE,
        ...nonEmptyFilters,
      };

      if (activeStatuses) {
        listDeviceParams.status__in = generalStatusesToDetailedStatuses(activeStatuses);
      }

      if (search != null && search.length > 0) {
        listDeviceParams.search = search;
      }

      const { data: devices, meta } = await listDeviceCards(listDeviceParams);

      const data: DeviceCardProps[] = devices.map((it) => {
        return {
          ...it,
          current_pest: it.current_pest,
          current_crop: it.current_crop,
          last_measurement: it.last_measurement,
          last_network_diagnostic: it.last_network_diagnostic,
          current_commission_status: it.current_commission_status,
          current_firmware: it.current_firmware,
          current_config: it.current_config,
          last_dc_and_dcd: it.last_dc_and_dcd,
          last_geo_point: it.last_geo_point,
        };
      });
      return { data, meta };
    },
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const data = React.useMemo(
    () => flatten((infData?.pages ?? [])?.map((it) => it.data)),
    [infData]
  );

  const [scrollRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: "0px 0px 1000px 0px",
  });

  const count = infData?.pages[0].meta.count;

  if (isLoading || isRefetching) {
    return <FlexLoader />;
  }

  if (data) {
    return (
      <>
        {theSnack}
        <Box>
          {count === 0 && (
            <Typography align="center" variant="body2">
              No devices matching the criteria.
            </Typography>
          )}

          <Grid container spacing={2}>
            {data.map((it) => (
              <Grid item xs={12} md={12} lg={6} key={it.id}>
                <DeviceCard
                  {...it}
                  ref={scrollRef}
                  setErrorList={setErrorList}
                  setSuccessList={setSuccessList}
                />
              </Grid>
            ))}
          </Grid>
          {hasNextPage && isFetchingNextPage && (
            <Box
              sx={{
                my: 6,
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Loader />
            </Box>
          )}
        </Box>
      </>
    );
  }
  return <Typography sx={{ px: 2, py: 2 }}>{String(error)}</Typography>;
}

export default DevicesList;

import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getValidatorDeviceById, getNextDeviceToValidation } from "queries/validators";

export const useDeviceInfoPanel = (deviceId: string) => {
  const [validationStartDate, setValidationStartDate] = useState<Date | null>(null);

  const queryClient = useQueryClient();
  const deviceDetailQuery = useQuery({
    queryKey: ["validator-device-detail", deviceId],
    queryFn: async () => getValidatorDeviceById(deviceId),
    onSuccess(data) {
      if (data.validation_start_date) {
        setValidationStartDate(new Date(data.validation_start_date * 1000));
      }
    },
    onError: (err) => {
      console.error("Error fetching validator device:", err);
    },
  });

  const nextDeviceQuery = useQuery({
    queryKey: ["validator-next-device", deviceId],
    queryFn: async () => getNextDeviceToValidation({ similar_to: deviceId }),
    onError: (err) => {
      console.error("Error fetching validator next device:", err);
    },
  });

  useEffect(() => {
    setValidationStartDate(null);
  }, [deviceId]);

  const reloadDeviceDetails = () => {
    queryClient.invalidateQueries({ queryKey: ["validator-device-detail"] });
  };

  return {
    deviceDetail: deviceDetailQuery.data,
    loading: deviceDetailQuery.isLoading,
    error: deviceDetailQuery.error,
    nextDeviceID: nextDeviceQuery.data?.id,
    reloadDeviceDetails,
    validationStartDate,
    setValidationStartDate,
  };
};

export default useDeviceInfoPanel;

import React from "react";
import { Box } from "@mui/material";
import DeviceTable from "./exportTabPage";

const DeviceManagement = () => {
  return (
      <DeviceTable />
  );
};

export default DeviceManagement;
import React from "react";
import { Typography } from "@mui/material";

type CropChipProps = {
  value?: string;
};

const CropChip: React.FC<CropChipProps> = ({ value }) => {
  return (
    <Typography 
      variant="body2" 
      fontWeight={400} 
      sx={{ color: '#27382C' }}
    >
      {(value && value.trim() !== '') ? value : "Unknown crop"}
    </Typography>
  );
};

export default CropChip;

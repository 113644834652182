import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";

type LureLifespanChipProps = {
    lifespan: number | null;
    remaining: number | null;
};

const LureLifespanChip: React.FC<LureLifespanChipProps> = ({ lifespan, remaining }) => {
    const valuation = lifespan !== null && remaining !== null 
        ? remaining > lifespan / 2 
            ? "success" 
            : remaining <= 0 
                ? "error" 
                : remaining < 7 
                    ? "error" 
                    : "warning" 
        : "warning";
    
    const label = remaining !== null 
        ? remaining <= 0 
            ? 'Lure expired'
            : `Lure remaining days: ${remaining}`
        : 'Lure lifespan not provided';

    return (
        <Chip
            icon={<InfoOutlined />}
            label={label}
            sx={{
                border: 'none',
                bgcolor: `${valuation}.light`,
                '& .MuiChip-icon, & .MuiChip-label': {
                    color: `${valuation}.dark`
                }
            }}
        />
    );
};

export default LureLifespanChip
/**
 * This file contains all the validator related querys included the queries getting validator devices.
 */

import axios from "axios";
import ListResponse from "types/listResponse";
import {
  ValidatorDevice,
  NextDeviceToValidate,
  NextDeviceToValidateParams,
  ListValidatorDeviceParams,
  Validator,
  ListValidatorParams,
} from "types/validators";

// Function to list validator devices with optional params
export const listValidatorDevices = async (
  params?: ListValidatorDeviceParams
): Promise<ListResponse<ValidatorDevice>> => {
  const { data } = await axios.get<ListResponse<ValidatorDevice>>("/api/validators/devices/", {
    params,
  });
  return data;
};

// Function to get a single validator device by ID
export const getValidatorDeviceById = async (id: string): Promise<ValidatorDevice> => {
  const { data } = await axios.get<ValidatorDevice>(`/api/validators/devices/${id}`);
  return data;
};

export const getNextDeviceToValidation = async (
  params?: NextDeviceToValidateParams
): Promise<NextDeviceToValidate> => {
  const { data } = await axios.get<NextDeviceToValidate>(
    "/api/validators/devices/next-device-to-validate/",
    { params }
  );
  return data;
};

export type UpdateValidatorDeviceDetailParams = {
  id: string;
  validation_start_date: number;
};

export const updateValidatorDeviceDetails = async ({
  id,
  validation_start_date,
}: UpdateValidatorDeviceDetailParams): Promise<void> => {
  const { data } = await axios.patch<void>(`/api/validators/devices/${id}`, {
    validation_start_date,
  });
  return data;
};

// Function to list validators
export const listValidators = async (
  params?: ListValidatorParams
): Promise<ListResponse<Validator>> => {
  const { data } = await axios.get<ListResponse<Validator>>("/api/validators/", { params });
  return data;
};

export const removeDevicesFromValidator = async (validatorId: string): Promise<void> => {
  await axios.patch(`/api/validators/${validatorId}/remove-all-devices/`);
}

export type BatchAssignDevicesToValidatorsParams = {
  validator_ids: string[];
  only_needing_validation?: boolean;
  device_statuses: string[];
};

export const batchAssignDevicesToValidators = async ( params?: BatchAssignDevicesToValidatorsParams): Promise<void> => {
  await axios.patch(`/api/devices/validator-batch-assign/`, params);
}
// See the following Notion table for the list of statuses and their mapping to general statuses:

import { ListDeviceItem } from "queries/devices";

// https://www.notion.so/scoutlabs-ag/4d122e0ba2a34d8bb4bd2d9b280ed964?v=6bc98e2c3a4442d7a3a2f77572fb6ab5
export const DEVICE_DETAILED_STATUSES = [
  "working",
  "recalled",
  "end_of_season",
  "not_installed",
  "spare",
  "uninstalled",
  "shipped_back",
  "planned_to_be_installed",
  "no_picture_today",
  "no_picture_for_multiple_days",
  "picture_problem",
  "cleaning_needed",
  "missing_sticky_sheet",
  "validation_needed",
  "low_battery_or_draining",
  "stopped_working",
  "camera_or_trap_broken",
  "wrong_coordinates",
  "not_validated",
  "maintenance_needed",
  "turned_off",
  "bad_images_for_multiple_days",
  "archived",
] as const;

export type DeviceDetailedStatus = (typeof DEVICE_DETAILED_STATUSES)[number];

// Statuses show to administrators by default (customers can use a toggle to see devices with all statuses)
export const DEVICE_GENERAL_STATUSES = [
  "working",
  "maintenance_needed",
  "not_working",
  "not_validated",
  "turned_off",
  "not_installed",
  "shipped_back",
  "archived",
] as const;

export type DeviceGeneralStatus = (typeof DEVICE_GENERAL_STATUSES)[number];

// Statuses show to customers by default
export const DEFAULT_CUSTOMER_DEVICE_STATUSES: DeviceGeneralStatus[] = [
  "maintenance_needed",
  "not_working",
  "working",
];

// Statuses which are expanded by default (accordion)
export const DEFAULT_EXPANDED_STATUSES: DeviceGeneralStatus[] = ["maintenance_needed", "working"];

export function statusToReadable(status: DeviceDetailedStatus | DeviceGeneralStatus): string {
  // replace underscores with spaces and capitalize first letter
  return status.replace(/_/g, " ").replace(/^./, (str) => str.toUpperCase());
}

// undefined - nothing to do
export const detailedStatusToAction: Record<DeviceDetailedStatus, string | undefined> = {
  working: undefined,
  recalled: undefined,
  end_of_season: undefined,
  not_installed: undefined,
  spare: undefined,
  uninstalled: undefined,
  shipped_back: undefined,
  planned_to_be_installed: undefined,
  no_picture_today: "Not sending pictures",
  no_picture_for_multiple_days: "Not sending pictures",
  picture_problem: "Problem with pictures",
  cleaning_needed: "Trap needs cleaning",
  missing_sticky_sheet: "Sticky sheet is missing",
  validation_needed: undefined,
  low_battery_or_draining: "Low battery, check the solar panel",
  stopped_working: undefined,
  camera_or_trap_broken: "Camera or trap is broken",
  wrong_coordinates: undefined,
  not_validated: undefined,
  maintenance_needed: undefined,
  turned_off: undefined,
  bad_images_for_multiple_days: "Bad images for multiple days",
  archived: undefined,
};

export const generalStatusToExplanation: Record<DeviceGeneralStatus, string> = {
  maintenance_needed: "Field maintenance needed",
  not_working: "Technical maintenance by scoutlabs",
  working: "Working traps",
  not_validated: "Images not validated",
  turned_off: "Turned off",
  not_installed: "Not installed",
  shipped_back: "Shipped back to scoutlabs",
  archived: "Archived traps",
};

export const detailedStatusToGeneralStatusMap: Record<DeviceDetailedStatus, DeviceGeneralStatus> = {
  working: "working",
  recalled: "not_installed",
  end_of_season: "turned_off",
  not_installed: "not_installed",
  spare: "not_installed",
  uninstalled: "not_installed",
  shipped_back: "shipped_back",
  planned_to_be_installed: "not_installed",
  no_picture_today: "not_working",
  no_picture_for_multiple_days: "not_working",
  picture_problem: "not_working",
  cleaning_needed: "maintenance_needed",
  missing_sticky_sheet: "maintenance_needed",
  validation_needed: "not_validated",
  low_battery_or_draining: "maintenance_needed",
  stopped_working: "not_working",
  camera_or_trap_broken: "maintenance_needed",
  wrong_coordinates: "not_working",
  not_validated: "not_validated",
  maintenance_needed: "maintenance_needed",
  turned_off: "turned_off",
  bad_images_for_multiple_days: "maintenance_needed",
  archived: "archived",
};

// reverse mapping
export function generalStatusToDetailedStatusesMap(): Record<
  DeviceGeneralStatus,
  DeviceDetailedStatus[]
> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const map: Record<DeviceGeneralStatus, DeviceDetailedStatus[]> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [detailedStatus, generalStatus] of Object.entries(detailedStatusToGeneralStatusMap)) {
    if (!map[generalStatus]) {
      map[generalStatus] = [];
    }
    map[generalStatus].push(detailedStatus as DeviceDetailedStatus);
  }

  return map;
}

// Reverse mapping for multiple statuses
export function generalStatusesToDetailedStatuses(generalStatuses: DeviceGeneralStatus[]) {
  const map = generalStatusToDetailedStatusesMap();
  return generalStatuses.map((v) => map[v]).flat();
}

// Device status returned by API (for admins only)
export type DeviceStatus = {
  detailed_status: DeviceDetailedStatus;
  general_status: DeviceGeneralStatus;
  approved: boolean;
};

/**
 * Get device general status from device object.
 * - admins also receive "last_status" object, which contains "general_status". This status
 *   can be newer than "general_status" in the device object. Admins see that status.
 * - regular users only receive "general_status" in the device object.
 */
export function getDeviceGeneralStatus(
  device: Pick<ListDeviceItem, "status_general" | "last_status">
): DeviceGeneralStatus {
  return device.last_status?.general_status ?? device.status_general;
}

/**
 * Get device detailed status from device object.
 * - admins also receive "last_status" object, which contains "detailed_status". This status
 *   can be newer than "detailed_status" in the device object. Admins see that status.
 * - regular users only receive "detailed_status" in the device object.
 */
export function getDeviceDetailedStatus(
  device: Pick<ListDeviceItem, "status" | "last_status">
): DeviceDetailedStatus {
  return device.last_status?.detailed_status ?? device.status;
}

export const DEVICE_STATUS_COLORS: Record<DeviceGeneralStatus, string> = {
  working: "#79AE89",
  maintenance_needed: "#7E2984",
  not_working: "#AB0B0B",
  not_validated: "#DB721F",
  turned_off: "#686368",
  not_installed: "#F4B71D",
  shipped_back: "#27382C",
  archived: "#686368",
} as const;

export const DEVICE_DETAILED_STATUS_COLORS: Record<DeviceDetailedStatus, string> = {
  working: "#79AE89",
  recalled: "#636B66",
  end_of_season: "#C6BAA9",
  not_installed: "#F4B71D",
  spare: "#457FF3",
  uninstalled: "#3A38C5",
  shipped_back: "#27382C",
  planned_to_be_installed: "#F5D991",
  no_picture_today: "#E081B2",
  no_picture_for_multiple_days: "#891551",
  picture_problem: "#D94350",
  cleaning_needed: "#EF8E41",
  missing_sticky_sheet: "#EFD741",
  validation_needed: "#DB721F",
  low_battery_or_draining: "#D4D7D5",
  stopped_working: "#980C0E",
  camera_or_trap_broken: "#6B0E17",
  wrong_coordinates: "#A47FAD",
  not_validated: "#DB721F",
  maintenance_needed: "#7E2984",
  turned_off: "#686368",
  bad_images_for_multiple_days: "#EFD741",
  archived: "#686368",
} as const;

export function getDeviceStatusColor(
  status: DeviceGeneralStatus | DeviceDetailedStatus,
  type: "general" | "detailed" = "general"
): string {
  if (type === "detailed") {
    return DEVICE_DETAILED_STATUS_COLORS[status as DeviceDetailedStatus] ?? "#ACB7C9";
  }
  return DEVICE_STATUS_COLORS[status as DeviceGeneralStatus] ?? "#ACB7C9";
}

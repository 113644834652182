import React from "react";
import { Tooltip } from "@mui/material";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
// TODO colors
type RssiChipProps = {
  value?: number;
};
const RssiChip: React.FC<RssiChipProps> = ({ value }) => {
  return (
    <Tooltip title="RSSI" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <SignalCellularAltOutlinedIcon sx={{ fontSize: 16 }} />
        <span style={{ fontSize: 14 }}>{value ? `${value.toFixed(2)} dBm` : "Unknown"}</span>
      </div>
    </Tooltip>
  );
};

export default RssiChip;

import React from "react";
import { Tooltip } from "@mui/material";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
// TODO colors

type FirmwareChipProps = {
  value?: string;
};
const FirmwareChip: React.FC<FirmwareChipProps> = ({ value }) => {
  return (
    <Tooltip title="Firmware" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <span style={{ fontSize: 14 }}>
          {(value && value.trim() !== '') ? value : "Unknown"}
        </span>
        <MemoryOutlinedIcon sx={{ fontSize: 16 }} />
      </div>
    </Tooltip>
  );
};

export default FirmwareChip;

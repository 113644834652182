import * as React from "react";
import { Box, TextField, InputAdornment, Tabs, Tab } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import Typography from "@mui/material/Typography";
import DatePickers from "pages/export/datePicker";
import { useAuth } from "context/AuthProvider";
import { USER_TYPE } from "queries/users";
import LogTable from "components/DeviceCard/LogTable";
import ExportTable from "./exportTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`device-tabpanel-${index}`}
      aria-labelledby={`device-tab-${index}`}
      {...other}
      sx={{ height: "100%" }}
    >
      {value === index && children}
    </Box>
  );
}

const DeviceTable = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const days = activeTab === 0 ? 90 : 10;
  const { isAdmin, userType } = useAuth();
  const canViewLogs = isAdmin || userType === USER_TYPE.MANAGER;

  const [dateRange, setDateRange] = React.useState({
    fromDate: new Date(new Date().setDate(new Date().getDate() - days)).toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
  });

  const toLogDate = (date: string) => {
    const tmpDate = new Date(date);
    tmpDate.setDate(tmpDate.getDate() + 1);
    const newDate = tmpDate.getTime() / 1000;
    return newDate;
  };

  const handleDateChange = (date: { fromDate: string; toDate: string }) => {
    setDateRange(date);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const datePickerWithSearch = (
    <Box sx={{ display: "flex", flexFlow: "row", gap: 2 }}>
      <Box
        sx={{
          width: "400px",
          "& .MuiTextField-root": {
            width: "190px",
          },
        }}
      >
        <DatePickers onDateChange={handleDateChange} />
      </Box>
      <TextField
        variant="outlined"
        placeholder="Search"
        sx={{
          width: 400,
          "& .MuiOutlinedInput-root": {
            borderRadius: 3,
            height: "56px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#A3A3A3" }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  return (
    <Box sx={{ display: "flex", flexFlow: "column", width: "100%", gap: 0.5, height: "100%" }}>
      <Typography variant="h4">Export data</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Choose the type of data you are interested in and select the date range to export.
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="General device data" />
          <Tab label="Device logs" />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <ExportTable
          fromDate={dateRange.fromDate}
          toDate={dateRange.toDate}
          dateAndSearch={datePickerWithSearch}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        {canViewLogs ? (
          <>
            <DatePickers onDateChange={handleDateChange} overrideDefaultDays={10} />
            <br />
            <LogTable
              fromDate={new Date(dateRange.fromDate).getTime() / 1000}
              toDate={toLogDate(dateRange.toDate)}
            />
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontSize="18px"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#DB721F",
              }}
            >
              <ReportOutlinedIcon fontSize="large" sx={{ mr: 1 }} />
              You do not have permission to view logs!
            </Typography>
          </Box>
        )}
      </TabPanel>
    </Box>
  );
};

export default DeviceTable;

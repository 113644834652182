import React, { useState } from "react";
import { pink } from '@mui/material/colors';
import {
    Typography,
    Button,
    Container,
    Dialog,
    Box,
} from "@mui/material";
import CloasableDialogTitle from "components/ClosableDialogTitle";
import { useAuth } from "context/AuthProvider";

type WrongEmailModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const WrongEmailModal: React.FC<WrongEmailModalProps> = ({ isOpen, onClose }) => {

    const { logout } = useAuth();

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" >
            <CloasableDialogTitle onClose={handleClose}>
                <Container
                    maxWidth="sm"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                        minHeight: "5em",
                        bgcolor: "#ffff",
                        padding: 4,
                        borderRadius: 4,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            marginBottom: 0.5,
                            color: "#555",
                            fontWeight: "bold"
                        }}
                    >
                        This invite link is connected to another account.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            marginBottom: 2,
                            color: "#555",
                        }}
                    >
                        To accept the invitation, log in with the account linked to the email you received. If you don’t have an account, log out and reopen the link to register.
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                    }}>
                        <Button
                            variant="contained"

                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Box>
                </Container>
            </CloasableDialogTitle>
        </Dialog>
    );
};
export default WrongEmailModal;
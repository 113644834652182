import React, { useEffect, useState } from "react";
import {
    Typography
} from "@mui/material";
import AuthProvider from "context/AuthProvider";
import AppWrapper from "components/AppWrapper";
import StartOrganization from "./Cards/start";
import CreateOrganization from "./Cards/createOrganization";

const InviteManagePage: React.FC = () => {

    const [currentCard, setCurrentCard] = useState<"start" | "create">("start");

    const onStartOrganizationClick = () => {
        setCurrentCard("create")
    }

    const renderCurrentCard = () => {
        if (currentCard === "start") {
            return <StartOrganization handleStartClick={onStartOrganizationClick} />;
        }
        if (currentCard === "create") {
            return (
                <CreateOrganization />
            );
        }
        return null;
    };

    return (
        <AppWrapper mainSxProps={{ width: "100%", maxWidth: "100%" }}>
            <Typography
                variant="h4"
                sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    color: "#32422c",
                }}
            >
                Invite & Manage
            </Typography>
            {renderCurrentCard()}
        </AppWrapper>
    );
};

export default InviteManagePage;

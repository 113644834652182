import React, { useEffect, useState } from "react";
import { Grid, Typography, Alert, Box, IconButton, Tooltip } from "@mui/material";
import { ValidatorDevice } from "types/validators";
import AppWrapper from "components/AppWrapper";
import { Loader } from "components/Loader";
import { Sort } from "@mui/icons-material";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { statusToReadable } from "utils/device-statuses";
import { useAuth } from "context/AuthProvider";
import ValidatorDeviceCard from "./ValidatorDeviceCard";
import { useValidatorDevices } from "./useValidatorDevices";

/* ---------- Sorting option constants ---------- */
enum SortOption {
  UnvalidatedImageCount = "-unvalidated_image_count", // Default option
  StatusNeedsApproval = "-status_needs_approval",
  ValidatorUsername = "validator_username",
}

const SORT_OPTIONS_ARRAY = Object.values(SortOption);

const SORT_LABELS = {
  [SortOption.UnvalidatedImageCount]: "Unvalidated Image Count",
  [SortOption.StatusNeedsApproval]: "Status Needs Approval",
  [SortOption.ValidatorUsername]: "Validator Username",
};
/* ---------- Sorting option constants end ---------- */

// Grid component to render the list of device cards
interface DeviceGridProps {
  devices: ValidatorDevice[];
}

const DeviceGrid: React.FC<DeviceGridProps> = ({ devices }) => {
  return (
    <Grid container spacing={4} sx={{ padding: "16px 0" }}>
      {devices.map((device) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={device.id}>
          <ValidatorDeviceCard
            smpID={device.smapp_id}
            uuid={device.id}
            pestName={device.pest_name || "Unknown Pest"}
            unvalidatedImageCount={device.unvalidated_and_visible_image_count}
            deviceStatus={statusToReadable(
              device.status_needs_approval ? device.last_not_approved_status! : device.status
            )}
            validatorName={device.validator_username ?? ""}
            isApprovalRequired={device.status_needs_approval}
          />
        </Grid>
      ))}
    </Grid>
  );
};

interface SortButtonProps {
  sortOption: number;
  handleSort: () => void;
}

const SortButton: React.FC<SortButtonProps> = ({ sortOption, handleSort }) => {
  return (
    <>
      <Typography variant="body2" sx={{ marginRight: "8px", color: "text.secondary" }}>
        Sort by: {SORT_LABELS[SORT_OPTIONS_ARRAY[sortOption]]}
      </Typography>

      <Tooltip title="Change sorting option">
        <IconButton
          sx={{
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: "8px",
            padding: "8px",
            transition: "background-color 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            },
          }}
          aria-label="sort devices"
          onClick={handleSort}
        >
          <Sort />
        </IconButton>
      </Tooltip>
    </>
  );
};

// Main component for the Validator Devices page
const ValidatorMain: React.FC = () => {
  const { isAdmin } = useAuth();
  const { devices, isLoading, error, hasMore, fetchMore, changeQueryParams } =
    useValidatorDevices();
  const [sortOption, setSortOption] = useState<number>(0);

  const [ref] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: fetchMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleSort = () => {
    const nextSortOption = (sortOption + 1) % SORT_OPTIONS_ARRAY.length;
    setSortOption(nextSortOption);
    changeQueryParams({ ordering: SORT_OPTIONS_ARRAY[nextSortOption] });
  };

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <AppWrapper mainSxProps={{ width: "95%", maxWidth: "95%" }}>
      <Box display="flex" alignItems="center" sx={{ marginBottom: "16px" }}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          Validator Devices
        </Typography>

        {isAdmin && <SortButton sortOption={sortOption} handleSort={handleSort} />}
      </Box>

      {devices.length > 0 ? (
        <>
          <DeviceGrid devices={devices} />
          {(isLoading || hasMore) && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </>
      ) : (
        !isLoading && <Typography variant="body1">No devices found.</Typography>
      )}
    </AppWrapper>
  );
};

export default ValidatorMain;

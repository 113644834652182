import { Box } from '@mui/material';
import { Loader } from 'components/Loader';
import { useAuth } from 'context/AuthProvider';
import Cookies from 'js-cookie';
import { refreshToken } from 'queries/auth';
import { acceptInvitation } from 'queries/inviteUser';
import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import ROUTES from 'routes';
import { setTokens } from 'utils/token';

const InvitationLinkRedirect: React.FC = () => {
    const location = useLocation();
    const { isLoggedIn, currentUser, handleAcceptInvitation } = useAuth();
    const [inviteAccepted, setInviteAccepted] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);

    const params = new URLSearchParams(location.search);

    const token = params.get('token');
    const email = params.get('email');

    const acceptInvite = async (token: string) => {
        try {
            await acceptInvitation({ token });
            const currentRefreshToken = Cookies.get("refresh")
            if (currentRefreshToken) {
                const newTokens = await refreshToken({ refresh: currentRefreshToken })
                setTokens(newTokens);
                setInviteAccepted(true);

            }
        } catch (err) {
            setInviteAccepted(null);
            setError((err as Error).message);
        }
    }

    useEffect(() => {
        if (!isLoggedIn || !token || !currentUser) {
            return;
        }
        if (email === currentUser.email) {
            acceptInvite(token)
                .then((res) => {
                    const currentRefreshToken = Cookies.get("refresh")
                    if (currentRefreshToken) {
                        refreshToken({ refresh: currentRefreshToken })
                            .then((res) => {
                                setTokens(res);
                                setInviteAccepted(true);
                            })
                    }
                })
        }
        else {
            handleAcceptInvitation(token);
            setInviteAccepted(false);
        }
    }, [currentUser, isLoggedIn, token, email, handleAcceptInvitation])



    if (inviteAccepted === true) {
        return <Navigate to={ROUTES.HOME} state={{ isInviteAccepted: true }} replace />;
    }
    if (inviteAccepted === false) {
        return <Navigate to={ROUTES.DASHBOARD} state={{ isInviteAccepted: false, error: "Email doesn't match, please log in with the right account" }} replace />;

    }
    if (!isLoggedIn) {
        return <Navigate to={`${ROUTES.REGISTER}?token=${token}&email=${email}`} replace />;
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100vw",
            }}
        >
            <Loader sx={{ vertical: "top", horizontal: "center" }} />
        </Box>
    );


};

export default InvitationLinkRedirect
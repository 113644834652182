import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Select,
  Typography,
  Chip,
  FormControl,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CustomSwitch } from "components/Image/ImageEditor/CustomComponents";
import { useQueryClient } from "@tanstack/react-query";
import {
  DEVICE_GENERAL_STATUSES,
  generalStatusToExplanation,
  generalStatusesToDetailedStatuses,
} from "utils/device-statuses";
import {
  batchAssignDevicesToValidators,
  BatchAssignDevicesToValidatorsParams,
} from "queries/validators";

interface AssignDevicesDialogProps {
  open: boolean;
  onClose: () => void;
  validators: string[];
}

const AssignDevicesDialog: React.FC<AssignDevicesDialogProps> = ({ open, onClose, validators }) => {
  const queryClient = useQueryClient();

  const [onlyRequireValidation, setOnlyRequireValidation] = React.useState(true);
  const [statuses, setStatuses] = React.useState<(typeof DEVICE_GENERAL_STATUSES)[number][]>([
    "working",
    "maintenance_needed",
    "not_working",
  ]);
  const [loading, setLoading] = React.useState(false);

  const handleStatusChange = (event: SelectChangeEvent<typeof statuses>) => {
    const {
      target: { value },
    } = event;
    setStatuses(
      typeof value === "string"
        ? [value as (typeof DEVICE_GENERAL_STATUSES)[number]]
        : (value as (typeof DEVICE_GENERAL_STATUSES)[number][])
    );
  };

  const handleAssignDevices = async () => {
    setLoading(true);
    try {
      const params: BatchAssignDevicesToValidatorsParams = {
        validator_ids: validators,
        only_needing_validation: onlyRequireValidation,
        device_statuses: generalStatusesToDetailedStatuses(statuses),
      };
      await batchAssignDevicesToValidators(params);
      queryClient.invalidateQueries({ queryKey: ["list-validators"] });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: "70vw",
          minHeight: "50vh",
          borderRadius: "24px",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle variant="h5">
        Assign validator devices - {validators.length} selected validators
      </DialogTitle>
      <DialogContent
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        <DialogContentText sx={{ mb: 8 }}>
          By clicking the Assign button, the system will randomly assign all the unassigned devices
          to the previously selected validators.
        </DialogContentText>
        <Box sx={{ display: "flex", alignItems: "center", mb: 8, gap: 3 }}>
          <Typography>Only assign devices currently requiring validation</Typography>
          <CustomSwitch
            checked={onlyRequireValidation}
            onChange={() => {
              setOnlyRequireValidation(!onlyRequireValidation);
            }}
            color1="#F4B71D"
            color2="#FFF"
          />
        </Box>
        <Box>
          <FormControl sx={{ width: "30%" }}>
            <Select
              multiple
              value={statuses}
              onChange={handleStatusChange}
              displayEmpty
              renderValue={() => "Select device statuses..."}
            >
              {DEVICE_GENERAL_STATUSES.map((status) => (
                <MenuItem key={status} value={status}>
                  {generalStatusToExplanation[status]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 2 }}>
            {statuses.map((status) => (
              <Chip
                key={status}
                label={generalStatusToExplanation[status]}
                onDelete={() => setStatuses(statuses.filter((item) => item !== status))}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAssignDevices}
          disabled={loading}
        >
          {loading ? "Assigning..." : "Assign"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default AssignDevicesDialog;

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

type ArchiveDeviceDialogProps = {
  open: boolean;
  smapp_id: string;
  onClose: () => void;
  onConfirm: () => void;
  isArchiving: boolean;
};

const ArchiveDeviceDialog = ({
  open,
  smapp_id,
  onClose,
  onConfirm,
  isArchiving,
}: ArchiveDeviceDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '16px',
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle>Confirm Archive</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive the device &quot;{smapp_id}&quot;? This action cannot be undone.
        </DialogContentText>
        <br />
        <DialogContentText>
          After archiving a device, it will no longer update or receive new data.
        </DialogContentText>
        <br />
        <DialogContentText>
          The physical device can be reinstalled, but it will create a new instance and function as a brand-new device.
        </DialogContentText>
        <br />
        <DialogContentText>
          Archived device data will remain in the system permanently.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          disabled={isArchiving}
        >
          {isArchiving ? 'Archiving...' : 'Archive'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveDeviceDialog; 
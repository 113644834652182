import React from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useInfiniteQuery } from "@tanstack/react-query";

import ValidatorItem from "./ValidatorItem";
import AddValidatorModal from "./Modals/AddValidatorModal";
import ValidatorDevicesManageModal from "./Modals/ValidatorDevicesManageModal";
import AssignDevicesDialog from "./Modals/AssignDevicesDialog";
import { useValidatorManagement } from "./useValidatorManagement";
import useValidatorSelection from "./useValidatorSelection";

const ValidatorManagementMain = () => {
  const {
    fetchValidators,
    searchTerm,
    setSearchTerm,
    openRegModal,
    openDevicesManageModal,
    handleOpenRegModal,
    handleCloseRegModal,
    handleOpenDevicesManageModal,
    handleCloseDevicesManageModal,
    register,
    errors,
    showPassword,
    isRegistrationInProgress,
    handleClickShowPassword,
    handleSaveAndClose,
    handleSaveAndAssignDevices,
    registerError,
    handleDeleteValidator,
    showDeleteError,
    setShowDeleteError,
    selectedValidatorId,
  } = useValidatorManagement();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery(
    ["list-validators", searchTerm],
    ({ pageParam = 1 }) => fetchValidators(pageParam, searchTerm),
    { getNextPageParam: (lastPage) => lastPage.nextPage }
  );

  const validators = React.useMemo(
    () => data?.pages.flatMap((page) => page.validators) ?? [],
    [data]
  );

  const {
    checked,
    validatorsChecked,
    toggleCheckAll,
    toggleValidatorCheck,
    someChecked,
    checkedValidators,
  } = useValidatorSelection(validators);

  const [openAssignDevicesDialog, setOpenAssignDevicesDialog] = React.useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Container maxWidth={false} sx={{ px: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h4">Validator Management</Typography>
        <Button variant="contained" onClick={handleOpenRegModal} endIcon={<AddCircleOutlineIcon />}>
          Add Validator
        </Button>
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        label="Find validator"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2, maxWidth: "40%", borderRadius: "10px" }}
      />

      <Box sx={{ mb: 1, textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={() => setOpenAssignDevicesDialog(true)}
          disabled={checkedValidators.length === 0}
        >
          Assign devices to selected...
        </Button>
      </Box>

      <Box sx={{ borderRadius: "24px", overflow: "hidden", boxShadow: 1 }}>
        <Box sx={{ height: 68, bgcolor: "#FFFFFF", display: "flex", alignItems: "center", pl: 6 }}>
          <Typography variant="h6" sx={{ color: "#27382C" }}>
            Validators
          </Typography>
        </Box>

        <TableContainer
          sx={{ maxHeight: "70vh", overflow: "auto", bgcolor: "white" }}
          onScroll={handleScroll}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ height: 60 }}>
                <TableCell sx={{ pl: 3 }}>NAME</TableCell>
                <TableCell sx={{ pl: 4 }}>NUMBER OF DEVICES</TableCell>
                <TableCell align="right" sx={{ pr: 4 }}>
                  ACTIONS
                </TableCell>
                <TableCell align="right" sx={{ pr: 4, width: "5%" }}>
                  <Checkbox
                    checked={checked}
                    indeterminate={!checked && someChecked}
                    onChange={toggleCheckAll}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {validators.map((validator) => (
                <ValidatorItem
                  key={validator.uuid}
                  id={validator.uuid}
                  name={validator.name}
                  numberOfDevices={validator.numberOfDevices}
                  handleDeleteClick={() => handleDeleteValidator(validator.uuid)}
                  handleAssignDevicesClick={() => handleOpenDevicesManageModal(validator.uuid)}
                  isChecked={
                    validatorsChecked.find((v) => v.validatorId === validator.uuid)?.isChecked ??
                    false
                  }
                  handleSingleCheck={() => toggleValidatorCheck(validator.uuid)}
                />
              ))}
            </TableBody>
          </Table>
          {(isLoading || isFetchingNextPage) && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </TableContainer>
      </Box>

      {/* Modals */}
      <AddValidatorModal
        open={openRegModal}
        onClose={handleCloseRegModal}
        register={register}
        errors={errors}
        showPassword={showPassword}
        isLoading={isRegistrationInProgress}
        registerError={registerError}
        handleClickShowPassword={handleClickShowPassword}
        handleSaveAndClose={handleSaveAndClose}
        handleAssignDevices={handleSaveAndAssignDevices}
      />

      <AssignDevicesDialog
        open={openAssignDevicesDialog}
        onClose={() => setOpenAssignDevicesDialog(false)}
        validators={checkedValidators}
      />

      {showDeleteError && (
        <Dialog open onClose={() => setShowDeleteError(false)}>
          <DialogTitle sx={{ color: "#AB0B0B" }}>Oh snap! :(</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "#AB0B0B" }}>
              Something went wrong while deleting the validator. Please try again later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#AB0B0B",
                color: "white",
                "&:hover": { backgroundColor: "#FBC1C1" },
              }}
              onClick={() => setShowDeleteError(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <ValidatorDevicesManageModal
        open={openDevicesManageModal}
        onClose={handleCloseDevicesManageModal}
        validatorId={selectedValidatorId}
      />
    </Container>
  );
};

export default ValidatorManagementMain;

/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";

export type ListFirmwareParams = {
  page: number;
  page_size: number;
  id__in?: string[];
  active?: boolean;
};

export type FirmwareNameAndVersions = {
  filename: string;
  board_type: string;
  major_version: string;
  minor_version: string;
  patch_version: string;
  is_compressed: boolean;
};

export type ListFirmwareItem = {
  id: string;
  active: boolean;
  created_at: number;
  updated_at: number;
  comment: string | null;
  checksum: string;
} & FirmwareNameAndVersions;

export const boardMajorMinorPatch = (f: FirmwareNameAndVersions | null) => {
  if (f == null) {
    return "";
  }
  return `${f.board_type}_${f.major_version}_${f.minor_version}_${f.patch_version}`;
};

export const listFirmwares = async (
  params?: ListFirmwareParams
): Promise<ListResponse<ListFirmwareItem>> => {
  const { data } = await axios.get<ListResponse<ListFirmwareItem>>("/api/firmwares/", {
    params: { active: true, ...params },
  });
  return data;
};

export const listAllFirmwares = listAllFactory(listFirmwares);

type DetailFirmwareParams = {
  id: string;
};
export type UpdateFirmwareParams = {
  data: FormData;
} & DetailFirmwareParams;
export type DetailFirmwareItem = ListFirmwareItem;
export const detailFirmware = async ({ id }: DetailFirmwareParams): Promise<DetailFirmwareItem> => {
  const { data } = await axios.get<DetailFirmwareItem>(`/api/firmwares/${id}`);
  return data;
};

export const uploadFirmware = async (data: FormData) => {
  await axios.post(`/api/firmwares/upload`, data);
};

export const updateFirmware = async ({ id, data }: UpdateFirmwareParams) => {
  await axios.patch(`/api/firmwares/${id}`, data);
};

export const deleteFirmware = async ({ id }: DetailFirmwareParams) => {
  await axios.delete(`/api/firmwares/${id}`);
};

export const downloadFirmware = async ({ id }: DetailFirmwareParams): Promise<Blob> => {
  const { data } = await axios.get(`/api/firmwares/${id}/download`, { responseType: "blob" });
  return data;
};

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomSwitch, CustomNumberInput } from "./CustomComponents";

type ImageDetails = {
  isDark: boolean;
  isShiny: boolean;
  isBlurry: boolean;
  requiresCleaning: boolean;
  fullnessLevel: number;
};

export type RightSidePanelProps = {
  isFullscreen: boolean;
  imageDetails: ImageDetails;
  onChange: (newDetails: Partial<ImageDetails>) => void;
  handleReset: () => void;
};

const RightSidePanel: React.FC<RightSidePanelProps> = ({
  isFullscreen,
  imageDetails,
  onChange,
  handleReset,
}) => {
  if (isFullscreen) return null;

  return (
    <>
      <Box sx={{ mx: "16px", border: 2, borderColor: "#d4d7d5" }} />
      <Box sx={{ width: "40%" }}>
        <Typography variant="h5" fontWeight="medium" sx={{ marginBottom: "16px" }}>
          Edit image details
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            pb: "16vh",
            pt: "10vh",
            pl: "1.5vw",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Button aria-label="reset" size="medium" color="inherit" onClick={handleReset}>
              <Box
                sx={{
                  color: "#F4B71D",
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "large",
                }}
              >
                <CloseIcon fontSize="medium" />
                Reset all
              </Box>
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Fullness level (%)
            </Typography>
            <CustomNumberInput
              value={imageDetails.fullnessLevel}
              setValue={(val) =>
                onChange({
                  fullnessLevel: typeof val === "function" ? val(imageDetails.fullnessLevel) : val,
                })
              }
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4vh" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" sx={{ flex: 1 }}>
                Too dark
              </Typography>
              <CustomSwitch
                checked={imageDetails.isDark}
                onChange={(e) => onChange({ isDark: e.target.checked })}
                color1="#27382C"
                color2="#fff"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" sx={{ flex: 1 }}>
                Too shiny
              </Typography>
              <CustomSwitch
                checked={imageDetails.isShiny}
                onChange={(e) => onChange({ isShiny: e.target.checked })}
                color1="#27382C"
                color2="#fff"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" sx={{ flex: 1 }}>
                Too blurry
              </Typography>
              <CustomSwitch
                checked={imageDetails.isBlurry}
                onChange={(e) => onChange({ isBlurry: e.target.checked })}
                color1="#27382C"
                color2="#fff"
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Cleaning required
            </Typography>
            <CustomSwitch
              checked={imageDetails.requiresCleaning}
              onChange={(e) => onChange({ requiresCleaning: e.target.checked })}
              color1="#27382C"
              color2="#fff"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RightSidePanel;

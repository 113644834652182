import { useQuery } from "@tanstack/react-query";
import { listAllImages } from "queries/devices/images";
import { useEffect, useState } from "react";

const useImageGridPanel = (deviceID: string) => {
  const getMonthTimestamps = (date: Date) => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

    return {
      startTimestamp: Math.floor(startOfMonth.getTime() / 1000),
      endTimestamp: Math.floor(endOfMonth.getTime() / 1000),
    };
  };

  const currentMonthTimestamps = getMonthTimestamps(new Date());

  const [selectedImages, setSelectedImages] = useState<(string | number)[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [params, setParams] = useState({
    device__id: deviceID,
    page_size: 100,
    ordering: "created_at" as const,
    created_at__gte: currentMonthTimestamps.startTimestamp,
    created_at__lte: currentMonthTimestamps.endTimestamp,
    raw_blob_name__isnull: false,
  });

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      device__id: deviceID,
    }));
  }, [deviceID]);

  const imagesQuery = useQuery({
    queryKey: [
      "list-all-images",
      params.created_at__gte,
      params.created_at__lte,
      params.device__id,
    ],
    queryFn: async () => (await listAllImages(params)).data,
    onError: (e) => {
      console.error("Failed to fetch images: ", e);
    },
    cacheTime: 600000,
  });

  const handleMonthChange = (newDate: Date | null) => {
    if (newDate) {
      setSelectedMonth(newDate);

      const { startTimestamp, endTimestamp } = getMonthTimestamps(newDate);

      setParams((prevParams) => ({
        ...prevParams,
        created_at__gte: startTimestamp,
        created_at__lte: endTimestamp,
      }));
    }
  };

  const handleImageSelect = (imageId: string) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(imageId)
        ? prevSelected.filter((id) => id !== imageId)
        : [...prevSelected, imageId]
    );
  };

  const handleValidateImage = (imageId: string) => {
    console.log(`Validating image with ID: ${imageId}`);
    // Add logic to validate image
  };

  return {
    imagesQuery,
    selectedImages,
    selectedMonth,
    handleMonthChange,
    handleImageSelect,
    handleValidateImage,
  };
};

export default useImageGridPanel;

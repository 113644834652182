import React, { useState } from "react";
import { pink } from '@mui/material/colors';
import { Controller, useForm } from "react-hook-form";
import {
    Typography,
    Button,
    TextField,
    Container,
    Dialog,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    Autocomplete
} from "@mui/material";
import CloasableDialogTitle from "components/ClosableDialogTitle";
import { SendInviteParams } from "queries/inviteUser";

type InviteMaintainerDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onSendInvitaion: (params: SendInviteParams) => Promise<void>;
    corporations: any[];
    setSearchTerm: (term: string) => void;
    fetchNextPage: () => void;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
};

const InviteMaintainer: React.FC<InviteMaintainerDialogProps> = ({ 
    isOpen, 
    onClose, 
    onSendInvitaion, 
    corporations,
    setSearchTerm,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
}) => {

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [emailColor, setEmailColor] = useState<"success" | "error" | "primary" | "secondary" | "info" | "warning">('primary')
    const [touched, setTouched] = useState<boolean>(false);
    const [role, setRole] = useState<string | null>(null);
    const { control, watch } = useForm();
    const selectedCorporation = watch("corporation");

    const resetStates = () => {
        setEmail('');
        setEmailError('');
        setEmailColor('primary');
        setTouched(false);
        setRole(null);
    }

    const handleClose = () => {
        onClose();
        resetStates();
    }

    const validateEmail = (email: string): boolean => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmail(value);

        if (touched) {
            setEmailError(validateEmail(value) ? '' : 'Invalid email address');
            setEmailColor(validateEmail(email) ? 'success' : 'error');
        }
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(event.target.value);
    };

    const handleBlur = (): void => {
        setTouched(true);
        setEmailError(validateEmail(email) ? '' : 'Invalid email address');
        setEmailColor(validateEmail(email) ? 'primary' : 'error');
    };

    const handleScroll = (event: React.SyntheticEvent) => {
      const list = event.currentTarget;
      if (
        list.scrollTop + list.clientHeight >= list.scrollHeight - 50 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    };

    const handleSubmit = async (): Promise<void> => {

        if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            return;
        }
        if (!role) {
            alert('Please select a role');
            return;
        }
        try {
            await onSendInvitaion({
                email,
                user_type: role,
                target_corporation: selectedCorporation?.id,
            });

            handleClose();
        } catch (error: any) {
            if (error.response?.status === 400 && error.response.data?.email) {
                setEmailError(error.response.data.email[0]);
                setEmailColor('error');
            }
        };
    };


    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" >
            <CloasableDialogTitle onClose={handleClose}>
                <Container
                    maxWidth="sm"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                        minHeight: "5em",
                        bgcolor: "#ffff",
                        padding: 4,
                        borderRadius: 4,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            marginBottom: 0.5,
                            color: "#555",
                            fontWeight: "bold"
                        }}
                    >
                        Invite a maintainer
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            marginBottom: 2,
                            color: "#555",
                        }}
                    >
                        Fill the email field with the email you want to send your invitation out to.
                        Then press the invite button to complete the invitation.
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between", width: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
                            <TextField
                                size="small"
                                label="Email"
                                color={emailColor}
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={handleBlur}
                                error={Boolean(emailError)}
                                helperText={emailError}
                                variant="outlined"

                            />

                            <Controller
                                name="corporation"
                                control={control}
                                defaultValue={null}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Autocomplete
                                    value={value || null}
                                    onChange={(_, newValue) => onChange(newValue)}
                                    onInputChange={(_, newValue) => setSearchTerm(newValue)}
                                    options={corporations}
                                    getOptionLabel={(option) => option?.name || ""}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Corporation"
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                    ListboxProps={{
                                        onScroll: handleScroll
                                    }}
                                />
                                )}
                            />

                            <RadioGroup row value={role} onChange={handleRoleChange} sx={{ marginTop: "5px" }}>
                                <FormControlLabel
                                    value="maintainer"
                                    control={<Radio size="small" />}
                                    label="Maintainer"
                                    color={pink[800]}
                                />
                                <FormControlLabel
                                    value="corporate_manager"
                                    control={<Radio size="small" />}
                                    label="Manager"
                                />
                            </RadioGroup>
                            {!role && <FormHelperText sx={{ marginTop: "-15px", padding: 0 }}>Please select an option</FormHelperText>}
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                alignSelf: "end"
                            }}
                            onClick={handleSubmit}
                        >
                            Invite
                        </Button>
                    </Box>
                </Container>
            </CloasableDialogTitle>
        </Dialog>
    );
};
export default InviteMaintainer;
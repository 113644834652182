import LaunchIcon from "@mui/icons-material/Launch";
import React, { useState } from "react";
import { Box, IconButton, Tooltip, Typography, Button, Slider } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import { useInfiniteQuery } from "@tanstack/react-query";
import useMapSettings from "hooks/useMapSettings";
import { LatLng, LatLngExpression, Icon as LeafletIcon, Map, PathOptions } from "leaflet";
import "leaflet/dist/leaflet.css";
import _debounce from "lodash/debounce";
import { ListLastGeolocationItem, listLastGeolocations } from "queries/devices/lastGeolocations";
import {
  MapContainer,
  MapContainerProps,
  Marker,
  Popup,
  TileLayer,
  Circle,
  LayerGroup,
} from "react-leaflet";
import { Link } from "react-router-dom";
import ROUTES from "routes";
import { GetDetectionCountColor, LeafletColoredHeatmap, LeafletColoredMarker } from "utils/commons";

const CIRCLES_DEAFULT_OPTION: PathOptions = {
  color: "transparent",
  fillColor: "green",
  fillOpacity: 0.15,
};

const CIRCLES_DEAFULT_RADIUS_IN_METER = 2500;
const MAP_MOVE_DEBOUNCER_MS = 350;

const HeatMapState = {
  ALL: <AllInclusiveIcon />,
  PINS: <LocationOnOutlinedIcon />,
  HEATMAP: <BugReportOutlinedIcon />,
};

const createIcon = (className: string) => {
  return new LeafletIcon({
    iconUrl: "/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    className,
  });
};

type ControlledDeviceMapProps = {
  center: LatLngExpression;
  zoom?: number;
  map?: Map | null;
  setMap?: React.Ref<Map>;
  data?: ListLastGeolocationItem[];
  isMainMap?: boolean;
} & MapContainerProps;

const HeatmapCircle: React.FC<{
  position: LatLng;
  detectionCountDelta: number;
  radius: number;
}> = ({ position, detectionCountDelta, radius }) => {
  return (
    <LayerGroup>
      {[...Array(5).keys()].map((i) => (
        <Circle
          key={`heatmap-circle-${position}-${i}`}
          center={position}
          radius={radius * (i + 1)}
          pathOptions={{
            ...CIRCLES_DEAFULT_OPTION,
            fillColor: GetDetectionCountColor(detectionCountDelta, LeafletColoredHeatmap),
          }}
        />
      ))}
    </LayerGroup>
  );
};

const VirtualLayer = React.memo(
  ({
    data,
    heatState,
    radius,
  }: {
    data: ListLastGeolocationItem[] | undefined;
    heatState: keyof typeof HeatMapState;
    radius: number;
  }) => (
    <>
      {(data ?? []).map(
        ({ id, smapp_id, name, geo_location: { longitude, latitude }, detection_count_delta }) => {
          const position = new LatLng(latitude, longitude);
          return (
            <LayerGroup key={`map-layer-group-${id}`}>
              {heatState !== "PINS" && (
                <HeatmapCircle
                  key={`heatmap-circle-group-${id}`}
                  position={position}
                  detectionCountDelta={detection_count_delta}
                  radius={radius}
                />
              )}
              {heatState !== "HEATMAP" && (
                <Marker
                  position={position}
                  key={id}
                  icon={createIcon(
                    GetDetectionCountColor(detection_count_delta, LeafletColoredMarker)
                  )}
                >
                  <Popup>
                    <Box>
                      <Typography variant="overline">
                        Detection count:{" "}
                        {detection_count_delta === null ? 0 : detection_count_delta}
                      </Typography>
                    </Box>
                    {name && (
                      <Box>
                        <Typography variant="overline">Name: {name}</Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography variant="overline">
                        Trap ID: <Link to={`${ROUTES.DEVICES}/${id}`}>{smapp_id}</Link>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="overline">
                        GPS: {latitude.toString().slice(0, -5)}, {longitude.toString().slice(0, -5)}
                        <Link
                          to={`http://www.google.com/maps/place/${latitude},${longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip
                            sx={{ paddingLeft: 1.5 }}
                            placement="top"
                            title="Open coordinate in Google maps"
                          >
                            <IconButton size="small">
                              <LaunchIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </Typography>
                    </Box>
                  </Popup>
                </Marker>
              )}
            </LayerGroup>
          );
        }
      )}
    </>
  )
);

export const ControlledDeviceMap: React.FC<ControlledDeviceMapProps> = ({
  center,
  zoom,
  map,
  setMap,
  data,
  isMainMap,
  ...props
}) => {
  const [heatState, setHeatState] = useState<keyof typeof HeatMapState>("ALL");
  const [radius, setRadius] = useState<number>(CIRCLES_DEAFULT_RADIUS_IN_METER);

  return (
    <>
      <MapContainer center={center} zoom={zoom} style={{ height: "100%" }} ref={setMap} {...props}>
        <TileLayer
          attribution="Google Maps Satellite"
          url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
        />
        <VirtualLayer data={data} heatState={heatState} radius={radius} />
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 1000,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#ededed",
            },
            borderRadius: 2,
            height: 45,
          }}
          onClick={() => {
            const states = Object.keys(HeatMapState) as (keyof typeof HeatMapState)[];
            const currentIndex = states.indexOf(heatState);
            const nextIndex = (currentIndex + 1) % states.length;
            setHeatState(states[nextIndex]);
          }}
        >
          {HeatMapState[heatState]}
        </Button>
      </MapContainer>
      {heatState !== "PINS" && isMainMap && (
        <Slider
          sx={{
            position: "absolute",
            top: { xs: 92.5, sm: 32.5 },
            right: 100,
            zIndex: 1000,
            width: "10vh",
            color: "#ededed",
            "& .MuiSlider-thumb": {
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 0px`,
              },
              "&.Mui-active": {
                boxShadow: `0px 0px 0px 8px #ededed42`,
              },
            },
          }}
          defaultValue={radius}
          step={500}
          min={500}
          max={5000}
          onChange={(_, value) => setRadius(value as number)}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      )}
    </>
  );
};

const geoKey = "list-all-last-geolocations-v2";

const DeviceMap: React.FC<{ firstDeviceLocation?: { lat: number; lng: number } }> = ({
  firstDeviceLocation,
}) => {
  const [map, setMap] = React.useState<Map | null>(null);
  const { center, setCenter, zoom, setZoom } = useMapSettings({ firstDeviceLocation });

  // TODO: globalize filters
  // TODO: handle loading and error properly
  const [eastWest, setEastWest] = React.useState<Pick<
    ListLastGeolocationItem,
    "east" | "west"
  > | null>();
  const [data, setData] = React.useState<ListLastGeolocationItem[]>([]);

  const debounceFn = React.useMemo(
    () =>
      _debounce((pMap: Map) => {
        const genPos = (pos: LatLng) => {
          return { lat: pos.lat, lng: pos.lng };
        };
        setEastWest({
          east: genPos(pMap.getBounds().getNorthEast()),
          west: genPos(pMap.getBounds().getSouthWest()),
        });
      }, MAP_MOVE_DEBOUNCER_MS),
    []
  );

  React.useEffect(() => {
    if (map) {
      const onMove = () => {
        setCenter(map.getCenter());
        debounceFn(map);
      };
      onMove();

      const onZoom = () => {
        setZoom(map.getZoom());
        debounceFn(map);
      };
      onZoom();

      map.on("move", onMove);
      map.on("zoom", onZoom);

      return () => {
        map.off("move", onMove);
        map.off("zoom", onZoom);
      };
    }

    return () => {};
  }, [map, debounceFn, setCenter, setZoom]);

  React.useEffect(() => {
    return () => debounceFn.cancel();
  }, [debounceFn]);

  const infiGeo = useInfiniteQuery({
    queryKey: [geoKey, eastWest],
    queryFn: async ({ pageParam = 1 }) => {
      const { data, meta } = await listLastGeolocations({
        page: pageParam,
        active: true,
        ...eastWest,
        page_size: 100,
      });
      return { data, meta };
    },
    enabled: Boolean(eastWest),
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });

  const newData = (infiGeo.data?.pages || []).flatMap((v) => v.data);

  React.useEffect(() => {
    if (newData.length > 0) {
      const appendData = newData.filter((v) => !data.some((vv) => vv.id === v.id));
      if (appendData.length > 0) {
        setData((pre) => [...pre, ...appendData]);
      }
    }
  }, [newData, data]);

  React.useEffect(() => {
    if (infiGeo.hasNextPage && !infiGeo.isFetchingNextPage) {
      infiGeo.fetchNextPage();
    }
  }, [infiGeo, infiGeo.hasNextPage, infiGeo.isFetchingNextPage]);

  return <ControlledDeviceMap data={data} center={center} zoom={zoom} map={map} setMap={setMap} isMainMap />;
};

export default DeviceMap;

import { useState } from "react";

const useDialog = () => {

    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState<boolean>(false);

    const openInviteUserDialog = () => {
        setIsInviteDialogOpen(true);
    }

    const closeInviteUserDialog = () => {
        setIsInviteDialogOpen(false);
    }

    return { isInviteDialogOpen, openInviteUserDialog, closeInviteUserDialog }
}

export default useDialog
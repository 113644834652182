import React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { InputAdornment, IconButton, Box, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Close, Image } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useValidation } from "context/ValidationProvider";
import { useChainValidation } from "context/ChainValidationProvider";

export const CustomSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== "color1" && prop !== "color2",
})<{ color1: string; color2: string }>(({ theme, color1, color2 }) => ({
  width: 56,
  height: 32,
  padding: 0,
  position: "relative",
  display: "flex",
  alignItems: "center",

  "& .MuiSwitch-switchBase": {
    padding: 0,
    width: "100%",
    height: "100%",
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
    position: "absolute",

    "&.Mui-checked": {
      transform: "translateY(-50%)",
      // color: "#fff",
      color: color2,

      "& + .MuiSwitch-track": {
        // backgroundColor: "#27382C",
        backgroundColor: color1,
        opacity: 1,
      },

      "& .MuiSwitch-thumb": {
        width: 26,
        height: 26,
        // backgroundColor: "#FFF",
        backgroundColor: color2,
        left: "calc(100% - 30px)",
      },
    },
  },

  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    // backgroundColor: "#27382C",
    backgroundColor: color1,
    position: "absolute",
    top: "50%",
    left: 6,
    transform: "translateY(-50%)",
    transition: theme.transitions.create(["left", "width", "height", "background-color"], {
      duration: 200,
    }),
  },

  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    // backgroundColor: "#FFF",
    backgroundColor: color2,
    // border: "2px solid #27382C",
    border: `2px solid ${color1}`,
    opacity: 1,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
  },
}));

const StyledTextField = styled(TextField)({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
    textAlign: "center",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
  "& .MuiOutlinedInput-root": {},
  "& fieldset": {
    borderColor: "#7d7d7d",
  },
});

export function CustomNumberInput({
  value,
  setValue,
}: {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}) {
  const handleIncrement = () => {
    setValue((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setValue((prev) => (prev > 0 ? prev - 1 : 0));
  };

  return (
    <StyledTextField
      type="number"
      variant="outlined"
      value={value}
      onChange={(e) => setValue(Number(e.target.value))}
      sx={{ width: "80px" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <IconButton
                size="small"
                sx={{ padding: "2px", height: "12px", width: "20px", color: "#000" }}
                onClick={handleIncrement}
              >
                <ArrowDropUpIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                sx={{ padding: "2px", height: "12px", width: "20px", color: "#000" }}
                onClick={handleDecrement}
              >
                <ArrowDropDownIcon fontSize="small" />
              </IconButton>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
}

export const UpperPart: React.FC = () => {
  const { isChainValidation, currrentImageIndex, allImageCount } = useChainValidation();
  const { onClose } = useValidation();
  return (
    <>
      {isChainValidation && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            marginBottom: 2,
          }}
        >
          <Image sx={{ mr: 0.5 }} /> {currrentImageIndex + 1} / {allImageCount}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton aria-label="close" size="small" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
    </>
  );
};

export const BottomButtons: React.FC<{
  handleSave: () => void;
  handleNotVisible: () => void;
  isVisible?: boolean;
}> = ({ handleSave, handleNotVisible, isVisible }) => {
  const { isChainValidation, hasNext, skipValidation } = useChainValidation();
  const { isSaving } = useValidation();

  return (
    <>
      {isChainValidation && (
        <Box>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            size="large"
            sx={{ m: 1 }}
            onClick={skipValidation}
          >
            Skip image
          </LoadingButton>
        </Box>
      )}
      <LoadingButton
        loading={isSaving}
        variant="contained"
        size="large"
        sx={{ m: 1 }}
        onClick={handleSave}
      >
        Save &amp; {isChainValidation && hasNext ? "next" : "close"}
      </LoadingButton>
      <Button variant="contained" size="large" sx={{ m: 1 }} onClick={handleNotVisible}>
        {isVisible ? "Not visible" : "Visible"}
      </Button>
    </>
  );
};

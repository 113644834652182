import React from "react";
import { Tooltip } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
// TODO colors

type TimezoneChipProps = {
  value?: string;
};
const TimezoneChip: React.FC<TimezoneChipProps> = ({ value }) => {
  return (
    <Tooltip title="Timezone" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#606963' }}>
        <span style={{ fontSize: 14 }}>{value ?? "Unknown"}</span>
        <AccessTimeOutlinedIcon sx={{ fontSize: 16 }} />
      </div>
    </Tooltip>
  );
};

export default TimezoneChip;

import { Grid } from "@mui/material";
import { DetailDeviceItem } from "queries/devices";
import React from "react";
import { useAuth } from "context/AuthProvider";
import BatteryVoltageChartPanel from "components/visualization/BatteryVoltageChartPanel";
import { USER_TYPE } from "queries/users";
import Identifiers from "./gridItems/Identifiers";
import Generics from "./gridItems/Generics";
import CurrentSettings from "./gridItems/CurrentSettings";
// import LastMeasurement from "./gridItems/LastMeasurement";
// import LastGeolocation from "./gridItems/LastGeolocation";
import LastNetworkDiagnostic from "./gridItems/LastNetworkDiagnostic";

type DataPanelProps = { device: DetailDeviceItem };
const DataPanel: React.FC<DataPanelProps> = ({ device }) => {
  const { isAdmin, userType } = useAuth();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} lg={3} sx={{ order: [1, 1, 1, 1] }}>
        <Identifiers device={device} />
      </Grid>
      <Grid item xs={12} sm={12} lg={6} sx={{ order: [2, 3, 3, 2] }}>
        <Generics device={device} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ order: [3, 2, 2, 3] }}>
        <CurrentSettings device={device} />
      </Grid>
      {/* <Grid item xs={12} sm={6} lg={3} sx={{ order: [4, 4, 4, 4] }}>
        <LastGeolocation device={device} />
      </Grid> */}
      {/* <Grid item xs={12} sm={6} lg={3} sx={{ order: [5, 5, 5, 6] }}>
        <LastMeasurement device={device} />
      </Grid> */}
      {(isAdmin || userType === USER_TYPE.MANAGER) && (
        <>
          <Grid item xs={12} sm={12} lg={6} sx={{ order: [4, 4, 4, 4] }}>
            <LastNetworkDiagnostic device={device} />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} sx={{ order: [5, 5, 5, 5] }}>
            <BatteryVoltageChartPanel deviceIdIn={[device.id]} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DataPanel;

/* eslint-disable react/no-unused-prop-types */
import { ListPestItem } from "queries/pests";
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import BatteryVoltageChip from "components/deviceChips/BatteryVoltageChip";
import SolarCellVoltageChip from "components/deviceChips/SolarCellVoltageChip";
import HumidityChip from "components/deviceChips/HumidityChip";
import TemperatureChip from "components/deviceChips/TemperatureChip";
import PestChip from "components/deviceChips/PestChip";
import CropChip from "components/deviceChips/CropChip";
import CommissionStatusChip from "components/deviceChips/CommissionStatusChip";
import LureLifespanChip from "components/deviceChips/LureLifespanChip";
import {
  DEVICE_TYPE,
  ListDeviceItem,
  ListDeviceCardItem,
  UpdateDeviceParams,
  updateDevice,
} from "queries/devices";
import { ListMeasurementItem } from "queries/devices/measurements";
import { useAuth } from "context/AuthProvider";
import RssiChip from "components/deviceChips/RssiChip";
import FirmwareChip from "components/deviceChips/FirmwareChip";
import ConfigChip from "components/deviceChips/ConfigChip";
import useImeiLabel from "hooks/useImeiLabel";
import AreaChip from "components/deviceChips/AreaChip";
import TimezoneChip from "components/deviceChips/TimezoneChip";
import LastSyncChip from "components/deviceChips/LastSyncChip";
import CorporateChip from "components/deviceChips/CorporateChip";
import AdminApprovalChip from "components/deviceChips/AdminApprovalChip";
import TrapFixingInstructionsChip from "components/deviceChips/TrapFixingInstructionsChip";
import { getDeviceDetailedStatus } from "utils/device-statuses";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import LastCatchBox from "components/deviceChips/LastCatchBox";
import { useNavigate } from "react-router-dom";
import ROUTES from "routes";
import { USER_TYPE } from "queries/users";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { useMutation } from "@tanstack/react-query";
import DeviceMoreOptionsButton from "./DeviceMoreOptionsButton";

export type DeviceCardProps = Omit<
  ListDeviceCardItem,
  | "current_pest"
  | "last_measurement"
  | "current_crop"
  | "last_network_diagnostic"
  | "current_commission_status"
  | "current_firmware"
  | "current_config"
> & {
  current_crop: string | null;
  current_pest: string | null;
  last_measurement: ListMeasurementItem | null;
  last_network_diagnostic: { id: string; rssi: number } | null;
  current_commission_status: string | null;
  current_firmware: string | null;
  current_config: { name: string; version: number } | null;
  last_dc_and_dcd?: {
    created_at: number | null;
    detection_count: number | null;
    detection_count_delta: number | null;
  } | null;
  setErrorList?: React.Dispatch<React.SetStateAction<string[]>>;
  setSuccessList?: React.Dispatch<React.SetStateAction<string[]>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DeviceCard = React.forwardRef<HTMLDivElement, DeviceCardProps>(
  (
    {
      id,
      name,
      type,
      smapp_id,
      imei,
      mac,
      last_measurement,
      current_firmware,
      last_network_diagnostic,
      current_commission_status,
      current_crop,
      current_pest,
      country,
      city,
      timezone,
      current_config,
      corporations,
      last_dc_and_dcd,
      last_status,
      status,
      status_general,
      lure_days_life_span,
      lure_days_life_remaining,
      setErrorList,
      setSuccessList,
    },
    ref
  ) => {
    const imeiLabel = useImeiLabel();
    const { isAdmin, userType } = useAuth();
    const canViewLogs = isAdmin || userType === USER_TYPE.MANAGER;
    const isValidMac = mac !== null;
    const { created_at: lastValidation = null, detection_count_delta: last = 0 } =
      last_dc_and_dcd ?? {};
    const { jsx: theSnack } = useCustomSnackBar();
    const navigate = useNavigate();
    const TODAY_STR = new Date().toISOString();

    const [localLureReset, setLocalLureReset] = React.useState<string | null>(null);
    const [deviceShouldOpen, setDeviceShouldOpen] = React.useState(true);

    const updateDeviceMutation = useMutation<ListDeviceItem, Error, UpdateDeviceParams, unknown>({
      mutationFn: updateDevice,
      onSuccess: () => {
        setLocalLureReset(TODAY_STR.split("T")[0]);
      },
    });

    // Calculate the remaining days based on local reset or original value
    const effectiveLureRemaining = localLureReset ? lure_days_life_span : lure_days_life_remaining;

    const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
      // Prevent navigation if clicking on an interactive element, Menu backdrop, Menu itself, or if text is selected
      if (
        !deviceShouldOpen ||
        (event.target as HTMLElement).closest('button, a, [role="button"]') ||
        (event.target as HTMLElement).getAttribute("role") === "presentation" ||
        (event.target as HTMLElement).closest('[role="menu"]') ||
        window.getSelection()?.toString()
      ) {
        return;
      }
      navigate(`${ROUTES.DEVICES}/${id}`);
    };

    return (
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid lightgrey",
          boxSizing: "border-box",
          borderRadius: "24px",
          transition: "background-color 0.2s ease-in-out",
          "&:hover": {
            borderColor: "darkgray",
            backgroundColor: "#fafafa",
            cursor: "pointer",
          },
        }}
        ref={ref}
        onClick={handleCardClick}
      >
        <CardContent>
          <Box sx={{ mb: 1, display: "flex" }}>
            <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
              <Stack direction="column">
                <Tooltip title={name ? "NAME" : "TRAP ID"} arrow>
                  <Typography color="#27382C" fontSize={20} fontWeight={600}>
                    {name ? (
                      <>
                        {name} <Typography variant="caption">{smapp_id}</Typography>
                      </>
                    ) : (
                      smapp_id
                    )}
                  </Typography>
                </Tooltip>
                <Tooltip title={isValidMac ? "MAC" : imeiLabel} arrow>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {isValidMac ? mac : imei}
                  </Typography>
                </Tooltip>
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  <BatteryVoltageChip value={last_measurement?.battery_voltage} />
                  <RssiChip value={last_network_diagnostic?.rssi} />
                  {isAdmin && <LastSyncChip value={last_measurement?.created_at} />}
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-end">
              <DeviceMoreOptionsButton
                id={id}
                isAdmin={isAdmin}
                smapp_id={smapp_id}
                setErrorList={setErrorList}
                setSuccessList={setSuccessList}
                canViewLogs={canViewLogs}
                onClick={() => setDeviceShouldOpen(false)}
                onClose={() => setDeviceShouldOpen(true)}
              />
              <AreaChip country={country} city={city} />
            </Stack>
          </Box>
          <Divider />
          {type === DEVICE_TYPE.Premium ? (
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <SolarCellVoltageChip value={last_measurement?.solar_cell_voltage} />
              <HumidityChip value={last_measurement?.humidity} />
              <TemperatureChip value={last_measurement?.temperature} />
            </Stack>
          ) : (
            <Box sx={{ height: "29px" }} />
          )}
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
              <TrapFixingInstructionsChip
                detailedStatus={getDeviceDetailedStatus({ status, last_status })}
              />
              <AdminApprovalChip lastStatus={last_status} />
            </Stack>
            <Stack direction="column" justifyContent="center" spacing={1} alignItems="flex-end">
              {isAdmin ? (
                <Stack direction="column" spacing={1} alignItems="flex-end">
                  <TimezoneChip value={timezone} />
                  <CommissionStatusChip value={current_commission_status ?? undefined} />
                  <FirmwareChip value={current_firmware ?? undefined} />
                  <ConfigChip value={current_config} />
                  {Array.isArray(corporations) && corporations.length > 0 ? (
                    <CorporateChip value={corporations.join(",")} />
                  ) : (
                    <Box sx={{ height: "21px" }} />
                  )}
                </Stack>
              ) : (
                <Box sx={{ height: "50px" }} />
              )}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start">
              <PestChip value={current_pest ?? undefined} />
              <CropChip value={current_crop ?? undefined} />
              <LastCatchBox lastValidation={lastValidation} catchCount={last ?? 0} />
            </Stack>
            <Stack direction="column" justifyContent="flex-end" spacing={1} alignItems="flex-end">
              <Stack direction="row" alignItems="center" spacing={1}>
                <LureLifespanChip
                  lifespan={lure_days_life_span}
                  remaining={effectiveLureRemaining}
                />
                {lure_days_life_span && (
                  <Tooltip title="Set lure replaced" arrow>
                    <IconButton
                      size="small"
                      onClick={() => {
                        updateDeviceMutation.mutate({
                          id,
                          params: {
                            target_lure_last_replaced: TODAY_STR.split("T")[0],
                            lure_last_replaced: TODAY_STR.split("T")[0],
                          },
                        });
                      }}
                      sx={{
                        color: "#27382C",
                        border: "1px solid rgba(39, 56, 44, 0.2)",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                        padding: "4px",
                        "&:hover": {
                          border: "1px solid #27382C",
                          backgroundColor: "rgba(39, 56, 44, 0.2)",
                        },
                      }}
                    >
                      <RestoreRoundedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }
);

export default DeviceCard;

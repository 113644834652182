import React from "react";
import AppWrapper from "components/AppWrapper";
import { Box, TextField } from "@mui/material";
import { debounce } from "lodash";
import DeviceFiltersDrawer from "./DeviceFiltersDrawer";
import DeviceFiltersFab from "./DeviceFiltersFab";
import DevicesList from "./DevicesList";
import DeviceGeneralStatusFilterChips from "./DeviceStatusFilterChips";

const DeviceMain: React.FC = () => {
  const [search, setSearch] = React.useState(sessionStorage.getItem("search") || "");

  const onSearchChanged = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(value);
        sessionStorage.setItem("search", value);
      }, 600),
    []
  );

  React.useEffect(() => {
    return () => {
      onSearchChanged.cancel();
    };
  }, [onSearchChanged]);

  return (
    <AppWrapper>
      <Box sx={{ display: "flex", flexFlow: "row" }}>
        <TextField
          sx={{ m: 1.5, width: "66%" }}
          label="Search"
          variant="standard"
          onChange={onSearchChanged}
          defaultValue={search}
        />
      </Box>

      <DeviceGeneralStatusFilterChips />

      <DevicesList search={search} />
      <DeviceFiltersFab />
      <DeviceFiltersDrawer />
    </AppWrapper>
  );
};

export default DeviceMain;

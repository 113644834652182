import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { accessToken, AccessTokenParams, refreshToken, TokenResponse } from "queries/auth";
import {
  registration,
  RegisterParams,
  DetailRegistrationItem,
  ListUserItem,
  USER_TYPE,
  detailUser,
  deleteUser,
} from "queries/users";
import { DecodedToken, decode, getTokens, removeTokens, setTokens } from "utils/token";
import ROUTES from "routes";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { boolean } from "yup";
import { acceptInvitation } from "queries/inviteUser";

// User is logged in if access token or refresh token is present.
// (doesn't mean we already have any user info)
// Should be correctly set already in initial render.
type AuthContextProps = {
  currentUser?: ListUserItem;
  userType: USER_TYPE | undefined;
  login: UseMutateFunction<TokenResponse, unknown, AccessTokenParams, unknown>;
  registerUser: (params: RegisterParams) => Promise<DetailRegistrationItem>;
  deleteUser: (id: string) => Promise<void>;
  tokens: { access?: DecodedToken; refresh?: DecodedToken };
  isLoggingIn: boolean;
  isRegistrationInProgress: boolean;
  isLoggedIn: boolean;
  logout: () => void;
  isAdmin: boolean;
  authError: AxiosError | null;
  registerError: AxiosError | null;
  deleteUserError: AxiosError | null;
  handleAcceptInvitation: (token: string) => void;
  isValidationRequired: boolean;
  refreshUser: () => void;
};

const INITIAL_VALUES: AuthContextProps = {
  currentUser: undefined,
  userType: undefined,
  login: () => { },
  registerUser: async () => ({} as DetailRegistrationItem),
  deleteUser: async () => { },
  tokens: {},
  isLoggingIn: false,
  isRegistrationInProgress: false,
  isLoggedIn: false,
  logout: () => { },
  isAdmin: false,
  authError: null,
  registerError: null,
  deleteUserError: null,
  handleAcceptInvitation: () => { },
  isValidationRequired: false,
  refreshUser: () => { }

};

const AuthContext = React.createContext<AuthContextProps>(INITIAL_VALUES);

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isInvitationAcceptRequired, setInvitationAcceptRequired] = useState<boolean>(false);
  const [invitationToken, setInvitationToken] = useState<string>("");

  const acceptInviteMutation = useMutation({
    mutationFn: async () => acceptInvitation({ token: invitationToken }),
    onSuccess: (res) => {
      const { refresh } = getTokens();
      if (refresh) {
        refreshToken({ refresh })
          .then((res => {
            setTokens(res);
            window.location.reload();
          })).catch((e) => {
            Sentry.captureException(e);
          })
      }
      setInvitationToken("");
      setInvitationAcceptRequired(false);
    },
    onError: (e: AxiosError) => {
      Sentry.captureException(e);
    },
  });

  // login (get access and refresh tokens)
  const loginMutation = useMutation<TokenResponse, AxiosError, AccessTokenParams, unknown>({
    mutationFn: accessToken,
    onSuccess: (res) => {
      const access = decode(res.access);
      // if REACT_APP_ALLOW_ALL is true -> then allow all users to access the app, otherwise only admin users
      if (process.env.REACT_APP_ALLOW_ALL || access.payload?.user.type === USER_TYPE.ADMIN) {
        setTokens(res);

        // enqueue notification
        const from = location.state?.from?.pathname || ROUTES.HOME;
        navigate(from, { replace: true });
        if (isInvitationAcceptRequired) {
          acceptInviteMutation.mutate();
        }
      }
    },
    onError: (e: AxiosError) => {
      Sentry.captureException(e);
    },
  });

  const handleAcceptInvitation = React.useCallback((token: string) => {
    setInvitationToken(token);
    setInvitationAcceptRequired(true);
  }, [])



  const registerUserMutation = useMutation<
    DetailRegistrationItem,
    AxiosError,
    RegisterParams,
    unknown
  >({
    mutationFn: registration,
    onSuccess: (res, variables) => {
      // After successful registration, attempt to log in
      loginMutation.mutate({
        username: res.username,
        password: variables.password, // Use the password from the original request
      });
      return res;
    },
    onError: (e: AxiosError) => {
      Sentry.captureException(e);
    },
  });

  const deleteUserMutation = useMutation<void, AxiosError, string>({
    mutationFn: (id: string) => deleteUser(id),
    onError: (e: AxiosError) => {
      Sentry.captureException(e);
    },
  });

  const logout = React.useCallback(() => {
    removeTokens();
    if ("caches" in window) {
      const clearIt = async () => {
        const cacheKeys = await window.caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            return window.caches.delete(key);
          })
        );
      };
      clearIt();
    }
    localStorage.clear();
    navigate(ROUTES.LOGIN, { replace: true });
  }, [navigate]);

  const { access, refresh } = getTokens();

  // pack decoded tokens into object
  const tokens = React.useMemo(() => {
    const tokens: AuthContextProps["tokens"] = {};

    if (access) tokens.access = decode(access);
    if (refresh) tokens.refresh = decode(refresh);
    return tokens;
  }, [access, refresh]);

  const userId = React.useMemo(
    () => tokens.access?.payload?.user_id,
    [tokens.access?.payload?.user_id]
  );

  // get user details
  const { data: currentUser, error: authError, refetch: refetchUser } = useQuery<ListUserItem, AxiosError>({
    queryKey: ["detail-user", { id: userId }],
    queryFn: () => detailUser({ id: userId as string }),
    enabled: !!userId,
    staleTime: 5000,
    cacheTime: 5000,
  });

  const refreshUser = React.useCallback(async () => {
    await refetchUser();
  }, [refetchUser]);

  // Logout user if we are unable to get user details
  React.useEffect(() => {
    if (currentUser && authError && authError.response?.status === 401) {
      logout();
    }
  }, [currentUser, authError, logout]);

  // If REACT_APP_ALLOW_ALL is false -> then only admin users can access the app, logout if not admin
  React.useEffect(() => {
    if (!process.env.REACT_APP_ALLOW_ALL && currentUser && currentUser.type !== USER_TYPE.ADMIN) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const value = React.useMemo(
    () => ({
      login: loginMutation.mutate,
      isLoggingIn: loginMutation.isLoading,
      logout,
      // User is logged in if access token or refresh token is present.
      // If we don't have access token, but do have refresh token, we are logged in
      // Access token will auto renew on next request (se interceptor in src/utils/axios.ts)
      isLoggedIn: !!access || !!refresh,
      tokens,
      refreshUser,
      currentUser,
      userType: tokens.access?.payload?.user.type as USER_TYPE,
      isAdmin: tokens.access?.payload?.user.type === USER_TYPE.ADMIN,
      authError: loginMutation.error,
      registerUser: async (params: RegisterParams): Promise<DetailRegistrationItem> => {
        const result = await registerUserMutation.mutateAsync(params);
        return result;
      },
      deleteUser: async (id: string): Promise<void> => {
        await deleteUserMutation.mutateAsync(id);
      },
      isRegistrationInProgress: registerUserMutation.isLoading || loginMutation.isLoading,
      registerError: registerUserMutation.error,
      deleteUserError: deleteUserMutation.error,
      handleAcceptInvitation,
      isValidationRequired: isInvitationAcceptRequired
    }),
    [
      loginMutation.mutate,
      loginMutation.isLoading,
      loginMutation.error,
      registerUserMutation,
      logout,
      access,
      refresh,
      tokens,
      currentUser,
      deleteUserMutation,
      handleAcceptInvitation,
      isInvitationAcceptRequired,
      refreshUser
    ]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);
